import React, { useRef, useEffect, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { bin, woodBorder } from "src/assets";
import { ReactNormailVideoPlayer } from "src/components";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import PrintIcon from "@mui/icons-material/Print";
import { Card, TextField, Tooltip } from "@mui/material";
import VisionImage from "src/components/VisionBoard/VisionImage";
import DeleteIcon from "@material-ui/icons/Delete";
import { s3baseUrl } from "src/config/config";
import {
  DetailVisionBoard,
  deleteVisionBoardImage,
  deleteVisionImage,
  uploadVisionBoard,
} from "src/DAL/VisionBoard/VisionBoard";
import CustomConfirmation from "src/components/CustomConfirmation";
import { useSnackbar } from "notistack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function VisionBoard() {
  const canvasRef = useRef(null);
  const visionBoxRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [draggedImageIndex, setDraggedImageIndex] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const initialMouseOffset = useRef({ x: 0, y: 0 });
  const initialTouchOffset = useRef({ x: 0, y: 0 });
  const [canvasSize, setCanvasSize] = useState({ width: 700, height: 700 });
  const { contentSettingData } = useContentSetting();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [visionText, setVisionText] = useState("");
  const [selected, setSelected] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openDeleteImage, setOpenDeleteImage] = useState(false);
  const [openLeavePage, setOpenLeavePage] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");

  const handleChange = (event) => {
    setIsSubmit(true);
    const { value } = event.target;
    setVisionText(value);
  };
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteVisionImage(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      // getVisionBoardDetail();
      // setPreviewImages(
      //   result?.vission_board ? result?.vission_board?.selected_images : []
      // );
      setPreviewImages((prevRecent) =>
        prevRecent.filter((entry) => entry._id !== deleteValue._id)
      );
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleAgreeDeleteImage = (value, i) => {
    setSelected(i);
    setOpenDeleteImage(true);
    setDeleteValue(value);
  };
  const handleDeleteImage = async (value) => {
    const result = await deleteVisionBoardImage(deleteValue._id);
    if (result.code == 200) {
      const updatedImages = [...images];
      updatedImages.splice(selected, 1);
      setImages(updatedImages);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawImages(ctx, updatedImages);

      enqueueSnackbar(result.message, { variant: "success" });
      setOpenDeleteImage(false);
      setSelected(0);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDeleteImage(false);
    }
  };
  const getVisionBoardDetail = async () => {
    const result = await DetailVisionBoard();
    if (result.code === 200) {
      setPreviewImages(
        result?.vission_board ? result?.vission_board?.selected_images : []
      );
      if (
        result?.vission_board &&
        result.vission_board?.board_images.length > 0
      ) {
        const savedImages = result.vission_board?.board_images.map((data) => ({
          _id: data._id,
          thumbnail_1: data.thumbnail_1,
          thumbnail_2: data.thumbnail_2,
          x: parseFloat(data.x_axis),
          y: parseFloat(data.y_axis),
          width: 250,
          height: 200,
          image: new Image(),
        }));

        savedImages.forEach(async (img) => {
          const blob = await fetch(s3baseUrl + img.thumbnail_2).then((r) =>
            r.blob()
          );
          img.image.src = URL.createObjectURL(blob);
          img.image.onload = () => {
            setImages((prevImages) => [...prevImages, img]);
          };
        });
      }
      setVisionText(result?.vission_board ? result.vission_board.note : "");
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  useEffect(() => {
    const handleResize = () => {
      const largeScreenThreshold = 1024;
      const width =
        window.innerWidth > largeScreenThreshold
          ? window.innerWidth - 340
          : window.innerWidth - 25;
      const height = window.innerHeight;
      setCanvasSize({ width, height });
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const imageSources = [];
    const loadedImages = [];

    imageSources.forEach((src, index) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = () => {
        const initialX = 50 * index;
        const initialY = 50 * index;
        loadedImages.push({
          image,
          src,
          x: initialX,
          y: initialY,
          width: 300,
          height: 250,
        });
        if (loadedImages.length === imageSources.length) {
          setImages(loadedImages);
        }
      };
    });
  }, []);
  useEffect(() => {
    getVisionBoardDetail();
  }, []);
  const drawImages = (ctx, images) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    images.forEach((img, index) => {
      const maxCanvasX = ctx.canvas.width - img.width;
      const maxCanvasY = ctx.canvas.height - img.height;

      // Ensure image is within canvas bounds
      const x = Math.min(Math.max(img.x, 0), maxCanvasX);
      const y = Math.min(Math.max(img.y, 0), maxCanvasY);
      ctx.drawImage(img.image, img.x - 30, img.y - 30, img.width, img.height);
      // Draw the delete icon
      if (index === images.length - 1) {
        const deleteIcon = new Image();
        deleteIcon.src = bin;
        deleteIcon.onload = () => {
          const iconSize = 24;
          const deleteIconX = x + img.width - iconSize;
          const deleteIconY = y;
          ctx.drawImage(
            deleteIcon,
            img.x - 30 + img.width - iconSize,
            img.y - 30,
            iconSize,
            iconSize
          );
          // for cursor on delete icon
          // canvasRef.current.classList.add("delete-icon-cursor");
        };
      }
    });
    const imageData = images.map((img) => ({
      _id: img._id,
      thumbnail_1: img.thumbnail_1,
      thumbnail_2: img.thumbnail_2,
      x_axis: img.x,
      y_axis: img.y,
    }));
    let postData = {
      note: visionText,
      board_images: imageData,
    };
    localStorage.setItem("auto_save_board", JSON.stringify(postData));
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const handleTouchStart = (e) => {
      const rect = canvas.getBoundingClientRect();
      const touchX = e.touches[0].clientX - rect.left;
      const touchY = e.touches[0].clientY - rect.top;

      for (let i = images.length - 1; i >= 0; i--) {
        const img = images[i];
        if (
          touchX >= img.x &&
          touchX <= img.x + img.width &&
          touchY >= img.y &&
          touchY <= img.y + img.height
        ) {
          setIsDragging(true);
          setDraggedImageIndex(i);
          initialTouchOffset.current = {
            x: touchX - img.x,
            y: touchY - img.y,
          };

          // Move the selected image to the end of the array
          const updatedImages = [...images];
          const [draggedImage] = updatedImages.splice(i, 1);
          updatedImages.push(draggedImage);
          setImages(updatedImages);

          break;
        }
      }
    };

    const handleTouchEnd = () => {
      setIsDragging(false);
      setDraggedImageIndex(null);
      localStorage.setItem("images", JSON.stringify(images));
    };

    const handleTouchMove = (e) => {
      if (isDragging && draggedImageIndex !== null) {
        const rect = canvas.getBoundingClientRect();
        const touchX = e.touches[0].clientX - rect.left;
        const touchY = e.touches[0].clientY - rect.top;

        const updatedImages = images.map((img, index) => {
          if (index === images.length - 1) {
            // Update the position of the topmost image
            return {
              ...img,
              x: touchX - initialTouchOffset.current.x,
              y: touchY - initialTouchOffset.current.y,
            };
          }
          return img;
        });

        setImages(updatedImages);
        drawImages(ctx, updatedImages);
      }
    };

    const handleMouseDown = (e) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;

      for (let i = images.length - 1; i >= 0; i--) {
        const img = images[i];
        const iconSize = 24; // size of the delete icon
        const deleteIconX = img.x + img.width - iconSize;
        const deleteIconY = img.y;
        if (
          mouseX >= deleteIconX &&
          mouseX <= deleteIconX + iconSize &&
          mouseY >= deleteIconY &&
          mouseY <= deleteIconY + iconSize
        ) {
          const imageToDelete = images[i];
          // Remove the image from the array

          handleAgreeDeleteImage(imageToDelete, i);

          return;
        }
        if (
          mouseX >= img.x &&
          mouseX <= img.x + img.width &&
          mouseY >= img.y &&
          mouseY <= img.y + img.height
        ) {
          setIsDragging(true);
          setDraggedImageIndex(i);
          initialMouseOffset.current = {
            x: mouseX - img.x,
            y: mouseY - img.y,
          };

          // Move the selected image to the end of the array to bring it to the front
          const updatedImages = [...images];
          const [draggedImage] = updatedImages.splice(i, 1);
          updatedImages.push(draggedImage);
          setImages(updatedImages);

          break;
        }
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      setDraggedImageIndex(null);
      localStorage.setItem("images", JSON.stringify(images));
    };

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      const mouseY = e.clientY - rect.top;
      // for cursor on delete icon
      let overDeleteIcon = false;
      let overImage = false;
      images.forEach((img) => {
        const iconSize = 24;
        const deleteIconX = img.x + img.width - iconSize;
        const deleteIconY = img.y;

        if (
          mouseX >= deleteIconX &&
          mouseX <= deleteIconX + iconSize &&
          mouseY >= deleteIconY &&
          mouseY <= deleteIconY + iconSize
        ) {
          overDeleteIcon = true;
        }
        if (
          mouseX >= img.x &&
          mouseX <= img.x + img.width &&
          mouseY >= img.y &&
          mouseY <= img.y + img.height
        ) {
          overImage = true;
        }
      });
      // for cursor on delete icon
      if (overDeleteIcon) {
        canvas.style.cursor = "pointer";
      } else if (overImage) {
        canvas.style.cursor = "move";
      } else {
        canvas.style.cursor = "default";
      }
      if (isDragging && draggedImageIndex !== null) {
        const updatedImages = images.map((img, index) => {
          if (index === images.length - 1) {
            return {
              ...img,
              x: mouseX - initialMouseOffset.current.x,
              y: mouseY - initialMouseOffset.current.y,
            };
          }
          return img;
        });

        setImages(updatedImages);
        drawImages(ctx, updatedImages);
      }
    };
    // for cursor on delete icon
    const handleMouseLeave = () => {
      canvas.style.cursor = "default";
    };
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);
    canvas.addEventListener("mouseleave", handleMouseLeave); // for cursor on delete icon

    canvas.addEventListener("touchstart", handleTouchStart);
    canvas.addEventListener("touchend", handleTouchEnd);
    canvas.addEventListener("touchmove", handleTouchMove);
    return () => {
      canvas.removeEventListener("mousedown", handleMouseDown);
      canvas.removeEventListener("mouseup", handleMouseUp);
      canvas.removeEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseleave", handleMouseLeave);

      canvas.removeEventListener("touchstart", handleTouchStart);
      canvas.removeEventListener("touchend", handleTouchEnd);
      canvas.removeEventListener("touchmove", handleTouchMove);
    };
  }, [images, isDragging, draggedImageIndex]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    if (images.length > 0) {
      drawImages(ctx, images);
    }
  }, [images, canvasSize]);
  const handleSubmit = async (e) => {
    const imageData = images.map((img) => ({
      _id: img._id,
      thumbnail_1: img.thumbnail_1,
      thumbnail_2: img.thumbnail_2,
      x_axis: img.x,
      y_axis: img.y,
    }));
    let postData = {
      note: visionText,
      board_images: imageData,
    };
    console.log(postData, "postData");
    const result = await uploadVisionBoard(postData);
    if (result.code == 200) {
      setIsSubmit(false);
      enqueueSnackbar("Board Saved Successfully", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };

  // here text is centered and large
  const saveAsPng = () => {
    setIsDownloading(true);

    // Create a temporary canvas to render the full content
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const fontSize = 30;
    const lineHeight = fontSize;
    const canvasWidth = 920;
    const margin = 20;
    // const maxWidth = canvasWidth - margin * 2;
    const maxWidth = canvasWidth - margin * 2;

    // Calculate the lines required for text
    const words = visionText.split(" ");
    const lines = [];
    let currentLine = "";
    words.forEach((word) => {
      const testLine = currentLine + word + " ";
      const testWidth = ctx.measureText(testLine).width;
      if (testWidth > 300 && currentLine.length > 0) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine = testLine;
      }
    });
    lines.push(currentLine.trim()); // Push the last line

    const textHeight = lines.length * lineHeight;

    // Calculate the height required for images
    const imageHeight = images.reduce(
      (maxY, img) => Math.max(maxY, img.y + img.height),
      0
    );

    // Set canvas dimensions based on text and image height
    canvas.width = canvasWidth;
    canvas.height = Math.max(1130, textHeight + imageHeight + 100);

    // Draw the background
    ctx.fillStyle = "#fff"; // Background color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the text at the top
    ctx.font = `bold ${fontSize}px Arial`;
    ctx.fillStyle = "black";
    let textYPosition = 50;
    lines.forEach((line, index) => {
      const textWidth = ctx.measureText(line).width;
      const textXPosition = (canvas.width - textWidth) / 2; // Center each line of text
      ctx.fillText(line, textXPosition, textYPosition + index * lineHeight);
    });

    // Draw the images below the text
    const imageYOffset = textYPosition + textHeight + 50; // Adjust the offset as needed
    images.forEach((img) => {
      ctx.drawImage(
        img.image,
        img.x,
        img.y + imageYOffset,
        img.width,
        img.height
      );
    });

    // Convert canvas to PNG
    canvas.toBlob((blob) => {
      const link = document.createElement("a");
      link.download = "vision-board.png";
      link.href = URL.createObjectURL(blob);
      link.click();

      setIsDownloading(false);
    }, "image/png");
  };

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const index = e.dataTransfer.getData("text");
    const rect = canvasRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    const droppedImage = new Image();
    droppedImage.crossOrigin = "Anonymous";
    droppedImage.src = s3baseUrl + previewImages[index]?.thumbnail_2;
    droppedImage.onload = () => {
      setImages((prevImages) => [
        ...prevImages,
        {
          _id: previewImages[index]._id,
          image: droppedImage,
          thumbnail_1: previewImages[index].thumbnail_1,
          thumbnail_2: previewImages[index].thumbnail_2,
          x,
          y,
          width: 250,
          height: 200,
        },
      ]);
    };
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (images.length > 0) {
      const timeoutId = setTimeout(() => {
        // handleSubmit();
        const imageData = images.map((img) => ({
          _id: img._id,
          thumbnail_1: img.thumbnail_1,
          thumbnail_2: img.thumbnail_2,
          x_axis: img.x,
          y_axis: img.y,
        }));
        let postData = {
          note: visionText,
          board_images: imageData,
        };
        localStorage.setItem("auto_save_board", JSON.stringify(postData));
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [images]);
  useEffect(() => {
    if (isSubmit) {
      const timeoutId = setTimeout(() => {
        // handleSubmit();
        const imageData = images.map((img) => ({
          _id: img._id,
          thumbnail_1: img.thumbnail_1,
          thumbnail_2: img.thumbnail_2,
          x_axis: img.x,
          y_axis: img.y,
        }));
        let postData = {
          note: visionText,
          board_images: imageData,
        };
        localStorage.setItem("auto_save_board", JSON.stringify(postData));
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [visionText]);
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-6" style={{ marginLeft: "13px" }}>
          <PageDescription
            parameter="vision_heading"
            else_title="Vision Board"
          />
        </div>
        <div className="col-lg-6 text-end"></div>
      </div>
      <section className="container top-section-video mt-3 mb-lg-3">
        <div className="row pt-70" id="assessment-container">
          <div className="col-lg-7 col-md-6 mb-2">
            <ReactNormailVideoPlayer
              url={contentSettingData?.vision_board_url}
            />
          </div>
          <div className="col-lg-5 col-md-6 vision_description">
            <div
              dangerouslySetInnerHTML={{
                __html: contentSettingData?.vision_description,
              }}></div>
          </div>
        </div>
      </section>
      <div className="row mt-4 mb-4" style={{ margin: "auto" }}>
        <div className="d-flex">
          <Tooltip
            title={
              previewImages && previewImages?.length == 10
                ? "You Cannot Upload More Then 10 Images"
                : ""
            }>
            <div className="vision-upload-card">
              <div
                className="vision-upload-icon"
                onClick={() => {
                  if (previewImages && previewImages?.length < 10) {
                    setOpenCreateModal(true);
                  }
                }}>
                <p className="mt-3">
                  Upload Image <br />
                  <CloudUploadIcon />
                </p>
              </div>
            </div>
          </Tooltip>

          <div className="scroll-card">
            <div className="d-flex">
              {previewImages &&
                previewImages.length > 0 &&
                previewImages.map((data, x) => {
                  return (
                    <>
                      <div
                        style={{
                          position: "relative",
                          width: "fit-content",
                        }}
                        draggable
                        onDragStart={(e) => handleDragStart(e, x)}>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            backgroundColor: "transparent",
                            zIndex: 10,
                          }}>
                          <Tooltip title="Delete Image">
                            <DeleteIcon
                              className="delete-icon"
                              style={{
                                position: "absolute",
                                top: "5px", // Adjust as needed
                                right: "5px", // Adjust as needed
                                zIndex: 20,
                                pointerEvents: "auto", // This makes the icon clickable
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleAgreeDelete(data);
                              }}
                            />
                          </Tooltip>
                        </div>
                        <Card
                          className="me-3 card-style"
                          style={{
                            minWidth: 75,
                            backgroundColor: "#9f00ff",
                            boxShadow: "none",
                            borderRadius: "0px",
                          }}
                          // onDragStart={(e) => handleDragStart(e, x)}
                        >
                          <div className="d-flex flex-column align-items-center mt-1 position-relative">
                            <img
                              src={s3baseUrl + data?.thumbnail_2}
                              width={75}
                              height={75}
                              style={{ objectFit: "fit", marginLeft: "8px" }}
                            />
                          </div>
                        </Card>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div
        className="row vision-box"
        style={{
          width: `${canvasSize.width}`,
          height: `${canvasSize.height}`,
        }}>
        <div className="col-12">
          <div className="row vision-text-field">
            <div className="col-lg-3 col-md-3 col-sm-12 mt-lg-3 mb-3"></div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-3 mb-lg-3">
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                label="Vision"
                multiline
                rows={2}
                name="visionText"
                value={visionText}
                onChange={(e) => handleChange(e)}
              />
            </div>

            <div className="col-lg-3 col-md-3 col-sm-12 mb-3 text-end">
              <button
                className="small-contained-button-search mt-3"
                onClick={handleSubmit}>
                Save Board
              </button>
            </div>
          </div>
        </div>

        <div
          className="col-12 vision-canvas"
          ref={visionBoxRef}
          style={{
            overflow: "auto",
            // border: "30px solid",
            // borderImage: `url(${woodBorder}) 30 round`,
          }}>
          <canvas
            id="myCanvas"
            ref={canvasRef}
            // width={canvasSize.width}
            // height={canvasSize.height}
            width={920}
            height={1000}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{
              backgroundColor: "#fff",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              marginBottom: "5px",
              border: "30px solid",
              borderImage: `url(${woodBorder}) 30 round`,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end vision-upload-icon">
          <div style={{ marginRight: "20px", marginTop: "15px" }}>
            <p className="download-icon-text">
              {isDownloading ? "Downloading..." : "Download Board"}
            </p>

            <PrintIcon
              onClick={saveAsPng}
              sx={{ marginRight: "45px", color: "#9f00ff" }}
            />
          </div>
        </div>
      </div>
      <VisionImage
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        setPreviewImages={setPreviewImages}
        previewImages={previewImages}
      />
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDeleteDiary}
      />
      <CustomConfirmation
        open={openDeleteImage}
        setOpen={setOpenDeleteImage}
        title={"Are you sure you want to delete ?"}
        handleAgree={handleDeleteImage}
      />
    </div>
  );
}
