import { CircularProgress } from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
// import { _dynamite_event_category_video_list_chat } from "src/DAL/Inception/Inception";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
// import Community from "../Community/Community";
// import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
// import { useChat } from "src/Hooks/ChatContext/ChatContext";
import // handleFeatureStatus,
// handle_delete_message,
// handle_send_message,
// handle_update_message,
// handle_validate_redirect,
// receive_delete_live_message_by_socket,
// receive_feature_status_by_socket,
// receive_new_live_message_by_socket,
// receive_update_live_message_by_socket,
// scroll_to_bottom,
"./live_event_functions";
import { useNavigate, useParams } from "react-router-dom";
// import ChatCard from "./components/Chat-Box/ChatCard";
// import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
// import CategoryCard from "./components/CategoryCard";
import CustomConfirmation from "src/components/CustomConfirmation";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import {
  get_live_event_detail,
  handle_validate_redirect,
} from "./live_event_functions";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LiveEventsDetail() {
  const { userInfo, socket } = useContentSetting();
  // const {
  //   get_users_list_against_event,
  //   handleSetIsChatShow,
  //   handleSetEventSlug,
  // } = useChat();
  const [showChat, setShowChat] = useState(true);
  const [newChat, setNewChat] = useState(false);
  const [isReply, setIsReply] = useState(false);
  const [parentMessage, setParentMessage] = useState({});
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [eventDetail, setEventDetail] = useState();
  const [liveChat, setLiveChat] = useState([]);
  const [pinnedComments, setPinnedComments] = useState([]);
  const [currentEventsArray, setCurrentEventsArray] = useState([]);
  const [upcomingEventsArray, setUpcomingEventsArray] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [image, setImage] = React.useState("");
  const [oldImage, setOldImage] = React.useState("");
  const [previews, setPreviews] = useState("");
  const [selectedComment, setSelectedComment] = useState({});
  const [formType, setFormType] = useState("ADD");
  const [openDelete, setOpenDelete] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isEventAvailable, setIsEventAvailable] = useState(true);
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();

  const handleTabClick = (e, row) => {
    if (row.tab_slug == "the-source") {
      window.history.replaceState(null, null, `/live-events/${params.id}`);
    } else {
      window.history.replaceState(
        null,
        null,
        `/live-events/${params.id}/${row.tab_slug}`
      );
    }
  };

  const handleChangeTabs = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleOpenReplyBox = (object) => {
    setParentMessage(object);
    setIsReply(true);
  };

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleRemoveAll = () => {
    handleRemove();
    setChatMessage("");
    setIsSubmitting(false);
    setParentMessage({});
    setFormType("ADD");
    setIsReply(false);
  };

  const handleSendMessage = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    handle_send_message(
      image,
      eventDetail,
      chatMessage,
      userInfo,
      isReply,
      parentMessage,
      socket
    );
    handleRemoveAll();
  };

  const handleMessageUpdate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    handle_update_message(
      eventDetail,
      oldImage,
      previews,
      image,
      chatMessage,
      socket,
      selectedComment
    );
    handleRemoveAll();
  };

  const handleDeleteLiveComment = async () => {
    handle_delete_message(eventDetail, selectedComment, socket);
    setOpenDelete(false);
  };

  const handleCommentEdit = (comment) => {
    setSelectedComment(comment);
    setChatMessage(comment.message);
    if (comment.file_url) {
      setPreviews(s3baseUrl + comment.file_url);
      setOldImage(comment.file_url);
    } else {
      setImage("");
      setPreviews("");
      setOldImage("");
    }
    setFormType("EDIT");
  };

  const handleCommentPin = (comment) => {
    handleFeatureStatus(comment, eventDetail, socket);
  };

  const handleCommentDelete = (comment) => {
    setSelectedComment(comment);
    setOpenDelete(true);
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  useEffect(() => {
    get_live_event_detail(
      params.id,
      setIsEventAvailable,
      params.category_slug,
      setTabValue,
      setEventDetail,
      setCurrentEventsArray,
      setUpcomingEventsArray,
      setShowChat,
      setIsLoading,
      setLiveChat,
      setPinnedComments,
      setNewChat,
      setShowScrollArrow,
      userInfo
    );
    setInterval(function () {
      setRefresh((r) => !r);
    }, 10000);
    return () => {
      // handleSetIsChatShow(false);
    };
  }, []);

  useEffect(() => {
    if (!isEventAvailable) {
      navigate(`/live-events`);
    }
  }, [isEventAvailable]);

  useEffect(() => {
    setTimeout(() => {
      setRefresh((r) => !r);
    }, 0);

    window.scrollTo(0, 0);
  }, [showChat]);

  useEffect(() => {
    if (userInfo.is_executive && userInfo.is_chat_allow && eventDetail) {
      handleSetEventSlug(eventDetail.event_slug);
      get_users_list_against_event(eventDetail.event_slug);
      // handleSetIsChatShow(true);
    }
  }, [eventDetail]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(eventDetail.feature_video, "eventDetail.feature_video.video_url");
  if (!isEventAvailable) {
    return <RecordNotFound title="Live Event Not Found" />;
  }

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete your live comment?"}
        handleAgree={handleDeleteLiveComment}
      />
      {eventDetail.title === null ||
        (eventDetail.title === undefined ? (
          <RecordNotFound title="Live Event Not Found" />
        ) : (
          <div className="container-fluid ps-5 ps-lg-5">
            <div className="row">
              <div className="col-12 text-center  mb-4 mt-3">
                {eventDetail.event_logo && (
                  <img
                    className="m-auto"
                    src={s3baseUrl + eventDetail.event_logo?.thumbnail_1}
                    alt=""
                  />
                )}
                {!eventDetail.event_logo && (
                  <h2>
                    <strong>{htmlDecode(eventDetail.title)}</strong>
                  </h2>
                )}
              </div>
              <div id="video-col" className="col-12 video-editor-portals">
                {eventDetail.feature_video?.video_url ? (
                  <div
                    className="col-12 video-editor-portals"
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.feature_video.video_url,
                    }}></div>
                ) : eventDetail.video_url ? (
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.video_url,
                    }}></div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <img
                      className="pods-image-style"
                      src={s3baseUrl + eventDetail.images?.thumbnail_1}></img>
                  </div>
                )}
              </div>
              {eventDetail.feature_video?.audio_file_url &&
                eventDetail.feature_video?.video_type === "audio" && (
                  <div className="col-12 text-center">
                    <div>
                      <audio
                        controlsList="nodownload"
                        className="w-100"
                        src={
                          s3baseUrl + eventDetail.feature_video.audio_file_url
                        }
                        controls
                      />
                    </div>
                  </div>
                )}
              {eventDetail.banner1_image &&
                eventDetail.banner1_image.thumbnail_1 && (
                  <div className="col-6 mt-4">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handle_validate_redirect(
                          eventDetail.banner1_link,
                          setIsLoading
                        )
                      }>
                      <img
                        src={
                          s3baseUrl + eventDetail.banner1_image.thumbnail_1
                        }></img>
                    </a>
                  </div>
                )}
              {eventDetail.banner2_image &&
                eventDetail.banner2_image.thumbnail_1 && (
                  <div className="col-6 mt-4">
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handle_validate_redirect(
                          eventDetail?.banner2_link,
                          setIsLoading
                        )
                      }>
                      <img
                        src={
                          s3baseUrl + eventDetail.banner2_image.thumbnail_1
                        }></img>
                    </a>
                  </div>
                )}
              <div className="col-12 mt-3">
                {eventDetail.feature_video?.detail_description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.feature_video.detail_description,
                    }}></div>
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: eventDetail.detail_description,
                    }}></div>
                )}
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

export default LiveEventsDetail;
