import { CircularProgress, IconButton } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CustomPopover from "src/components/CustomPopover";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import CustomConfirmation from "src/components/CustomConfirmation";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import {
  deleteMeditationDailyApi,
  listAllMeditationsApi,
} from "src/DAL/MeditationDaily/MeditationDaily";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MeditationList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [diaryList, setDiaryList] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [editValue, setEditValue] = useState("");

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleEdit = (value) => {
    setEditValue(value);
    console.log(value, "value edited");
    navigate(`/daily-meditation`, {
      state: value,
    });
  };

  const handleDeleteDiary = async (value) => {
    const result = await deleteMeditationDailyApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });

      getDiaryList(false);
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };

  const getDiaryList = async (loadingValue) => {
    if (loadingValue == false) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }

    let result = await listAllMeditationsApi();
    if (result.code == 200) {
      const meditations = result?.meditation?.map((meditation) => {
        return {
          ...meditation,
          meditation_name: meditation.meditation_name,
          meditationDate: moment(meditation.meditation_date).format(
            "YYYY-MM-DD"
          ),
        };
      });
      setDiaryList(meditations);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  useEffect(() => {
    getDiaryList();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Meditation?"}
        handleAgree={handleDeleteDiary}
      />

      <section className="dynamite-diary">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate(-1)}>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className="col-lg-6 mb-4">
              <h2 className="dashboard_description">
                Daily Meditation Past Activities
              </h2>
            </div>
            <div className="col-lg-6  mb-4"></div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="recent col-lg-12 d-flex justify-content-end me-2 pe-3"></div>

            {diaryList?.length < 1 ? (
              <RecordNotFound title={"Diary not found"} />
            ) : (
              diaryList?.map((value, i) => {
                return (
                  <div className="col-lg-6 mb-3" style={{ cursor: "pointer" }}>
                    <div className="mini-cards d-flex">
                      <div className="w-100 ms-2 me-2">
                        <div className="diary-font-weight d-flex">
                          <h4
                          // onClick={() => handleEdit(value)}
                          >
                            {value?.meditationDate ? value?.meditationDate : ""}
                          </h4>
                          <div className="d-flex">
                            <CustomPopover menu={MENU_OPTIONS} data={value} />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                          <div onClick={() => handleEdit(value)}>
                            <h4>{value.meditation_name}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default MeditationList;
