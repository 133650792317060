import React from "react";
import { useState } from "react";
import MUICustomTabs from "src/components/GeneralComponents/MUICustomTabs";
import MainPart from "./MainPart";
import MainPartMyPosts from "./MainPartMyPosts";
import SyncIcon from "@mui/icons-material/Sync";

export default function MobilesTabs({
  showInfoBox,
  setShowInfoBox,
  userInfo,
  handleMyPosts,
  myPostsPage,
}) {
  const [tabValue, setTabValue] = useState(0);
  const [myPosts, setMyPosts] = useState(false);

  //Changing tab values
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const TABS_OPTIONS = [
    {
      title: myPostsPage ? "My Posts" : "Community Area",
      component: (
        <>
          {myPostsPage ? (
            <MainPartMyPosts
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              userInfo={userInfo}
              myPosts={true}
              myPostsPage={myPostsPage}
              event_id=""
            />
          ) : (
            <MainPart
              showInfoBox={showInfoBox}
              setShowInfoBox={setShowInfoBox}
              myPostsPage={myPostsPage}
              userInfo={userInfo}
              event_id=""
            />
          )}
        </>
      ),
    },
    {
      title: "Rewards",
      component: (
        <>
          {" "}
          <div className="col-12 pb-4">
            <div className="feed-mini-cards">
              <h3>Rewards</h3>
            </div>
            <div className="feed-mini-cards mt-2">
              <h3>Whats On</h3>
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      {tabValue == 0 ? (
        <div className="row">
          <div className="col-lg-6 col-sm-12 text-center">
            <h2>{myPostsPage ? "My Posts" : "Community Area"}</h2>
          </div>
          {myPostsPage ? (
            <div className="col-lg-5 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleMyPosts}>
                {" "}
                <SyncIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    marginRight: "3px",
                  }}
                />
                Community Area
              </button>
            </div>
          ) : (
            <div className="col-lg-5 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleMyPosts}>
                {" "}
                <SyncIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    marginRight: "3px",
                  }}
                />
                My Posts
              </button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-6 col-sm-12 text-center">
              <h2>Rewards</h2>
            </div>
          </div>
        </>
      )}

      <MUICustomTabs
        data={TABS_OPTIONS}
        value={tabValue}
        handleChange={handleChange}
        // className="mt-4"
      />
    </>
  );
}
