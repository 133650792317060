import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useSnackbar } from "notistack";
import Modal from "@mui/material/Modal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import {
  complete_challenge,
  complete_challenge_and_post,
  uploadMultipleImageOns3,
} from "src/DAL/Challenges/Challenges";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { s3baseUrl } from "src/config/config";
import { winner } from "src/assets";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "45%",
    marginTop: "2%",
    marginBottom: "2%",
  },
}));
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 640,
  maxWidth: "100%",
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
  overflow: "auto",
};
export default function Greetings({
  openCreateModal,
  setOpenCreateModal,
  setConfettiPlay,
  handleRecordingsandResources,
}) {
  const handleClose = () => {
    setConfettiPlay(true);
    setOpenCreateModal(false);
    setTimeout(() => {
      setConfettiPlay(false);
    }, 8000);
    handleRecordingsandResources();
  };

  return (
    <div>
      <Modal
        // open={true}
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style} className="modal-theme">
          <div
            className="text-center modalIcon col-12 create-post-title-box"
            onClick={() => {
              handleClose();
            }}>
            {/* <h2>Congratulations!</h2> */}
            <span>x</span>
          </div>
          <div className="challenge-success mb-4">
            <img
              src={winner}
              alt="Winner"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </div>
          <div className="challenge-success mt-2 mb-0">
            <p>
              Congratulations, you have completed the challenge successfully!
            </p>
          </div>

          <div className="col-12 text-center mt-3 notes-buttons">
            <button
              className="small-contained-button-notes post-submit-btn"
              // disabled={isLoading || isLoadingPost}
              onClick={handleClose}>
              Close
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
