import React, { useState } from "react";
import { Card, Tooltip, CircularProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { s3baseUrl } from "src/config/config";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { htmlDecode } from "src/utils/convertHtml";
import DocumentModelBox from "src/components/ModelBox/DocumentModelBox";
function ChallengeResourceCard({ imageLink, resource }) {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [previewDoc, setPreviewDoc] = React.useState("");
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [documentType, setDocumentType] = useState([]);
  console.log(resource, "resourceresourceresource");
  const handleOpen = (value) => {
    console.log(value, "==== value ====");
    let path = "";
    if (value.resource_type === "audio") {
      setPreviewDoc(value);
      path = value.resource_file;

      setDocumentType(value.resource_type);
      setImageUrl(s3baseUrl + path);
    } else if (value.resource_type === "embed_code") {
      setPreviewDoc(value);
      setDocumentType(value.resource_type);
      // handleNavigateEmbed(value);
      setImageUrl(s3baseUrl + path);
    } else {
      // setPreviewDoc(null);
      setDocumentType(value.resource_type);
      setPreviewDoc(value);
      path = value.resource_file;
      setImageUrl(s3baseUrl + path);
    }
    // handleDownload(path);
    console.log(s3baseUrl + path, "s3baseUrl + path");
    setOpen(true);
  };
  const handleCloseImage = () => setOpen(false);

  const handleDownload = async (value) => {
    let path = "";
    if (value.resource_type === "audio") {
      path = value.resource_file;
    } else {
      path = value.resource_file;
    }
    setIsLoading(true);
    const file_path = `${s3baseUrl}${path}`;
    console.log(file_path, "file_path");
    // window.open(file_path, "_blank");
    fetch(file_path)
      .then(async (res) => {
        if (res.status === 200) {
          let blob = await res.blob();
          saveAs(blob, path);
          setIsLoading(false);
        } else {
          enqueueSnackbar(res.statusText, { variant: "error" });
          setIsLoading(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        // setIsLoading(false);
      });
  };

  const getResourceImage = () => {
    if (resource.resource_thumbnail) {
      return s3baseUrl + resource.resource_thumbnail;
    } else {
      const ext = resource.resource_file.split(".").pop();
      if (imageLink[ext]) {
        return imageLink[ext];
      } else {
        return imageLink.other;
      }
    }
  };

  return (
    <>
      <div
        className="card pods-cards-shadow h-100"
        style={{ width: "100%", cursor: "default" }}>
        <div className="row card-body">
          <div className="col-3">
            <img
              src={getResourceImage()}
              className="ps-3"
              height="80px"
              alt=""
            />
          </div>
          <div className="col-7">
            <div>
              <h3 className="h2-heading">{htmlDecode(resource.title)}</h3>
            </div>
            <p className="programme-card-desc mb-3">
              {htmlDecode(resource.description)}
            </p>
          </div>
          <div className="col-2 d-flex" style={{ justifyContent: "end" }}>
            <div>
              <Tooltip title="Preview">
                <VisibilityIcon
                  onClick={() => {
                    handleOpen(resource);
                  }}
                  style={{ cursor: "pointer" }}
                  className="icon-style"
                />
              </Tooltip>
            </div>
            {resource.resource_type != "embed_code" && (
              <div style={{ marginLeft: "10px" }}>
                <Tooltip title="Download">
                  <DownloadIcon
                    onClick={() => {
                      handleDownload(resource);
                      // handleNavigateEmbed(value);
                    }}
                    style={{ cursor: "pointer" }}
                    className="icon-style"
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </div>
      <DocumentModelBox
        open={open}
        handleClose={handleCloseImage}
        image_url={imageUrl}
        type={documentType}
        previewDoc={previewDoc}
      />
    </>
  );
}

export default ChallengeResourceCard;
