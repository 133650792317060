import React from "react";
import ReactPlayer from "react-player";

const ReactFeedsVideoPlayer = (props) => (
  <>
    <div className="radius-wrapper">
      <ReactPlayer
        controls
        className="radius-player"
        url={props.url}
        width="100%"
        height="100%"
        stopOnUnmount
        pip
        playsinline
      />
    </div>
  </>
);

export default ReactFeedsVideoPlayer;
