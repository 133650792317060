import { invokeApi } from "../../bl_libs/invokeApi";

export const listAffirmationDailyApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/get_member_affirmation_logs_by_date`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const listAllGratitudesApi = async () => {
  const requestObj = {
    path: `api/gratitude/get_all_gratitude`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const listAllActiveAffirmations = async () => {
  const requestObj = {
    path: `api/affirmation/active_affirmations`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const listRecent = async () => {
  const requestObj = {
    path: `api/affirmation/get_recent_affirmations`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const addAffirmationDailyApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/add_member_affirmation_logs`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const updateAffirmationDailyApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/update_member_affirmation_log`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteAffirmationDailyApi = async (data) => {
  const requestObj = {
    path: `api/affirmation/delete_member_affirmation_log`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
