import React from "react";
import { Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { htmlDecode } from "src/utils/convertHtml";
import { s3baseUrl } from "src/config/config";

function ChallengeRecording({ recording }) {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/challenges-recording-detail/${recording._id}`, {
      state: recording,
    });
  };

  return (
    <>
      <Card className="lesson-card">
        <div className="row p-3">
          <div className="col-lg-2 col-md-3 col-sm-12">
            <img
              className="lesson-card-image"
              src={s3baseUrl + recording.video_thumbnail.thumbnail_1}
              alt="name"
              onClick={handleClick}
            />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <h3 className="lesson-heading" onClick={handleClick}>
              {htmlDecode(recording.title)}
            </h3>
            <p className="normal-font" onClick={handleClick}>
              {htmlDecode(recording.description)}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
}

export default ChallengeRecording;
