import { invokeApi } from "../../bl_libs/invokeApi";
export const uploadVisionImage = async (data) => {
  const requestObj = {
    path: `app/upload_vission_board_image`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const DetailVisionBoard = async () => {
  const requestObj = {
    path: `api/vission_board/detail_vission_board`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const VisionBoardImages = async () => {
  const requestObj = {
    path: `api/vission_board/detail_vission_board`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const deleteVisionImage = async (id) => {
  const requestObj = {
    path: `api/vission_board/delete_vission_board_selected_image/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteMemberVisionBoard = async () => {
  const requestObj = {
    path: `api/vission_board/delete_member_vission_board`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const deleteVisionBoardImage = async (id) => {
  const requestObj = {
    path: `api/vission_board/delete_vission_board_image/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const uploadVisionBoard = async (data) => {
  const requestObj = {
    path: `api/vission_board/add_vission_board`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
