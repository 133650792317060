import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { get_date_with_user_time_zone } from "src/utils/constants";

export default function ActionBarComponentProps({
  diaryDate,
  handleChangeDate,
  firstDiaryDate,
}) {
  const { adminTimeZone, userInfo } = useContentSetting();

  const get_update_time_with_time_zone = (date) => {
    let new_date = get_date_with_user_time_zone(
      date,
      "MM-DD-YYYY",
      userInfo.time_zone,
      adminTimeZone
    );
    return new_date;
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="static-date-picker">
        <StaticDatePicker
          value={diaryDate}
          onChange={(newValue) => handleChangeDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={new Date()}
          // minDate={firstDiaryDate ? firstDiaryDate : new Date()}
          minDate={firstDiaryDate ? firstDiaryDate : new Date()}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
