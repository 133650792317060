import PropTypes from "prop-types";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import { Box, Card, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import LockIcon from "@mui/icons-material/Lock";
// utils
import Label from "../../Label";
import { s3baseUrl } from "../../../config/config";

// ----------------------------------------------------------------------

const ProgrammesImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

ProgrammesCard.propTypes = {
  product: PropTypes.object,
};

export default function ProgrammesCard({ programm }) {
  const navigate = useNavigate();
  const {
    _id,
    title,
    program_slug,
    short_description,
    program_images,
    locked_program_info,
    no_of_lesson,
    total_lesson_duration,
    program_lock_status,
  } = programm;

  return (
    <Card
      className="programm-card h-100 secondary-background"
      sx={{ cursor: "pointer", position: "relative" }}
      onClick={() =>
        // program_lock_status === true
        //   ? "":
        navigate(`/programmes/${program_slug}`, {
          state: programm,
        })
      }>
      <Box sx={{ position: "relative", cursor: "pointer" }}>
        {program_lock_status === true ? (
          <button
            variant="contained"
            className="small-contained-button program-lock-icon top-50 start-50 translate-middle"
            style={{
              zIndex: 9,
              top: "45%",
              left: "22%",
              fontSize: "14px",
              position: "absolute",
              padding: "10px 20px",
            }}>
            <LockIcon className="lock-icon-color" />
            &nbsp;&nbsp;Locked
          </button>
        ) : (
          ""
        )}
        <ProgrammesImgStyle
          alt={title}
          src={s3baseUrl + program_images.thumbnail_2}
        />
      </Box>

      <Stack spacing={2} className="programme-content">
        <h3
          dangerouslySetInnerHTML={{
            __html: title,
          }}></h3>
        <p className="programme-card-desc">{short_description}</p>
        {/* <div className="card-button lesson-text">
          <p className="programme-duration">
            <span>
              {no_of_lesson} Lessons &nbsp; | &nbsp; {total_lesson_duration}
            </span>
          </p>
        </div> */}
      </Stack>
    </Card>
  );
}
