import { Card } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactMeditationPlayer from "src/components/ReactPlayers/ReactMeditationPlayer";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DoneIcon from "@mui/icons-material/Done";

export default function MeditationVideos({
  title,
  videoUrl,
  value,
  setWatchedVideos,
  watchedVideos,
  index,
  currentPlayingIndex,
  setCurrentPlayingIndex,
  selectedObject,
}) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const [isWatched, setIsWatched] = useState(false);
  const handleRecordingPlay = (recordingId) => {
    if (currentVideo === recordingId && isPlaying) {
      // If the same video is playing, pause it
      setIsPlaying(false);
    } else {
      // If a different video is clicked, set it as the current video and start playing
      setCurrentVideo(recordingId);
      setIsPlaying(true);
    }
    if (!watchedVideos.includes(recordingId)) {
      // If it doesn't exist, then push it
      let videosArray = [...watchedVideos];
      videosArray.push(recordingId);
      console.log("videosArray", videosArray);
      setWatchedVideos(videosArray);
    } else {
      console.log("Recording already watched");
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
    setCurrentPlayingIndex(index);
    handleRecordingPlay(value._id);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
  useEffect(() => {
    if (selectedObject && selectedObject.meditations) {
      const foundMeditation = selectedObject.meditations.find(
        (meditation) => meditation.video_url === videoUrl
      );
      setIsWatched(!!foundMeditation); // Set isWatched to true if foundMeditation is truthy
    } else {
      setIsWatched(false);
    }
  }, [videoUrl, selectedObject]);
  useEffect(() => {
    if (currentPlayingIndex !== index) {
      setIsPlaying(false);
    }
  }, [currentPlayingIndex, index]);
  return (
    <>
      <Card
        sx={{ borderRadius: "13px", boxShadow: "none" }}
        className="pragram-acc card-full-width review-card h-100">
        <div className="row ml-0 mr-0 h-100">
          <div className="col-12 feedback-programme-header set-max-height">
            <ReactMeditationPlayer
              url={videoUrl}
              type={""}
              value={value}
              playing={isPlaying}
              onPause={handlePause}
              onPlay={handlePlay} // Pass the recording ID to the callback function
            />
          </div>
          <div className="col-9">
            <p className="meditation-videos-text">{title}</p>
          </div>
          <div
            className="col-3"
            style={{
              position: "absolute",
              bottom: "10px",
              right: "25px",
              display: "flex",
              alignItems: "center",
            }}>
            {isWatched && (
              <>
                {/* <CheckCircleOutlineIcon style={{ marginRight: "5px" }} /> */}
                <DoneIcon
                  style={{ marginRight: "5px" }}
                  fontSize="large"
                  sx={{
                    color: "green !important",
                    width: "23px",
                    height: "23px",
                    stroke: "green",
                    strokeWidth: "2px",
                  }}
                />
                <span>Watched</span>
              </>
            )}
          </div>
        </div>
      </Card>
    </>
  );
}
