import { CircularProgress, Slider, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import LockIcon from "@mui/icons-material/Lock";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  accept_challenge,
  challenge_member_list_api,
  list_challenge_member,
} from "src/DAL/Challenges/Challenges";
import { s3baseUrl } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import moment from "moment";
import { coins, playIcon } from "src/assets";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Challenges() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [challengeList, setChallengeList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [challengeId, setChallengeId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [memberArray, setMemberArray] = useState([]);
  const [started, setStarted] = useState([]);
  const [acceptingChallengeId, setAcceptingChallengeId] = useState(null);

  const getChallengeListing = async () => {
    const result = await list_challenge_member();
    if (result.code === 200) {
      // if (
      //   result.started_challenge &&
      //   Object.keys(result.started_challenge).length > 0
      // ) {
      //   const newChallenge = result.challenge.filter(
      //     (item) => item._id !== result.started_challenge?.challenge_id._id
      //   );
      //   setChallengeList(newChallenge);
      // } else {
      //   setChallengeList(result.challenge);
      // }
      setChallengeList(result.incomplete_challenges);
      setStarted(
        result.started_challenge.length > 0 ? result.started_challenge : []
      );
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChallengeListing();
  }, []);

  const handleAcceptChallenge = async (value) => {
    if (started.length > 0) {
    } else {
      setAcceptingChallengeId(value._id);
      setIsAcceptLoading(true);
      const result = await accept_challenge(value._id);
      if (result.code === 200) {
        // setChallengeList(result.challenge);
        navigate(`/challenge-detail/${value._id}`, {
          state: result.challenge_history,
        });
        setIsAcceptLoading(false);
        setAcceptingChallengeId(null);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
        setIsAcceptLoading(false);
      }
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row pt-70" id="assessment-container">
        <div className="col-6 mb-4">
          <PageDescription
            parameter="challenges_heading"
            else_title="Challenges"
          />
        </div>
        <div className="col-lg-6 text-end mb-2">
          <button
            className="small-contained-button"
            onClick={() => navigate(`/past-challenges`)}>
            Past Activities{" "}
          </button>
        </div>
      </div>

      {started.length > 0 && (
        <>
          {started?.map((data, index) => {
            let Days = [
              {
                value: 0,
                label: "Day 1",
              },

              {
                value: 100,
                label: `Day ${data?.challenge_id?.number_of_days}`,
              },
            ];
            return (
              <div
                className="row challenge-box position-relative mb-4 started"
                onClick={() =>
                  navigate(`/challenge-detail/${data?.challenge_id?._id}`, {
                    state: data,
                  })
                }
                style={{ cursor: "pointer" }}>
                <div className="col-lg-4 col-md-12 image-div position-relative">
                  <img
                    src={
                      s3baseUrl +
                      data?.challenge_id?.video_thumbnail?.thumbnail_1
                    }
                    width={320}
                    height={230}
                    alt="Challenge Image"
                    className="image"
                    style={{ objectFit: "fill" }}
                  />
                  <div className="play-icon">
                    {/* <i class="fa-solid fa-play fa-2x"></i> */}
                    <img src={playIcon} />
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 text-md-start text-center mt-md-0 mt-4 visioneering-box position-relative">
                  <div className="assetssment-para visioneering-list-description">
                    <h3>{data?.challenge_id?.title}</h3>
                    <p>{data?.challenge_id?.short_description}</p>
                  </div>
                  <div className="d-flex justify-content-between flex-column-on-small">
                    <div className="challenge-date">
                      <p>
                        <span className="challenge-date">Start Date</span> :{" "}
                        {moment(data?.createdAt).format("DD-MM-YYYY")}
                      </p>
                    </div>
                    <div className="challenge-day-in-total">
                      {/* <p>
                        Current Day / Total Days :
                        {" " +
                          data.days_between +
                          "/" +
                          data.challenge_id?.number_of_days}
                      </p> */}
                    </div>
                  </div>
                  <div class="habits-slider d-flex justify-content-between mt-4 mb-5">
                    <div class="slider-value habits-gray-description mb-2">
                      {/* <span>{`${Math.round(
                        (data.completed_days /
                          data?.challenge_id?.number_of_days) *
                          100
                      )}%`}</span> */}
                      <span>Status</span>
                    </div>
                    <Slider
                      style={{
                        color: "#fff",
                        marginBottom: "30px",
                      }}
                      // valueLabelFormat={`${Math.round(
                      //   (data.completed_days /
                      //     data?.challenge_id?.number_of_days) *
                      //     100
                      // )}% Completed`}
                      aria-label="Custom marks"
                      defaultValue={
                        (data.completed_days /
                          data?.challenge_id?.number_of_days) *
                        100
                      }
                      marks={Days}
                      // valueLabelDisplay="on"
                      // getAriaValueText={valuetext}
                      // getAriaValueText={"helo"}
                      step={10}
                      className="mui-slider"
                      disabled
                    />
                  </div>
                  <div className="button-container text-end view-challenge-button-box mt-3 mt-md-0">
                    <button
                      className="small-contained-button"
                      onClick={() =>
                        navigate(
                          `/challenge-detail/${data?.challenge_id?._id}`,
                          {
                            state: data,
                          }
                        )
                      }>
                      View Challenge{" "}
                      <NavigateNextIcon
                        sx={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "3px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="my-2 challenges">
            {challengeList.length > 0 && <hr />}
          </div>
        </>
      )}

      {challengeList.length > 0 ? (
        <>
          {challengeList?.map((data, index) => {
            return (
              <div className="row challenge-box position-relative mb-4">
                <div className="col-lg-4 col-md-12 image-div position-relative">
                  <img
                    src={s3baseUrl + data?.video_thumbnail?.thumbnail_1}
                    alt="Challenge Image"
                    className="image"
                    width={320}
                    height={230}
                    style={{ objectFit: "fill" }}
                  />
                  <div className="play-icon">
                    {/* <i class="fa-solid fa-play fa-2x"></i> */}
                    <img src={playIcon} />
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 text-md-start text-center mt-md-0 mt-4 visioneering-box position-relative">
                  <div className="assetssment-para visioneering-list-description">
                    <h3>{data?.title}</h3>
                    <p>{data?.short_description}</p>
                  </div>

                  <div className="text-end button-container view-challenge-button-box mt-3 mt-md-0">
                    <Tooltip
                      title={
                        started.length > 0
                          ? "To start a new challenge, you must  complete the started one"
                          : ""
                      }>
                      <button
                        className="small-contained-button"
                        onClick={() => handleAcceptChallenge(data)}
                        disabled={
                          isAcceptLoading && acceptingChallengeId === data._id
                        }>
                        {isAcceptLoading && acceptingChallengeId === data._id
                          ? "Accepting..."
                          : "Accept Challenge"}
                        {!(
                          isAcceptLoading && acceptingChallengeId === data._id
                        ) && (
                          <LockIcon
                            sx={{
                              width: "25px",
                              height: "25px",
                              marginLeft: "3px",
                            }}
                          />
                        )}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          {started.length <= 0 && (
            <RecordNotFound title={"Challenges Not Found"} />
          )}
        </>
      )}
    </div>
  );
}
