import React from "react";
import { Box, Card, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { htmlDecode } from "src/utils/convertHtml";
import CustomPopover from "./CustomPopover";

const ImageStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "auto",
  objectFit: "cover",
});

// ----------------------------------------------------------------------

export default function CustomMUICard({
  data,
  handleClick,
  MENU_OPTIONS,
  className,
  lockStatement,
}) {
  return (
    <div className={`row ${className}`}>
      {data.map((item, index) => {
        return (
          <div className={item.card_classes} key={index}>
            <Card className="mui-custom-card h-100" sx={{ cursor: "pointer" }}>
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                }}>
                {item.card_button && item.card_button.button_title && (
                  <button
                    variant="contained"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    className={
                      lockStatement
                        ? `small-contained-button mui-card-button d-flex centerAlign ${
                            index == 0 ? "centerAlign0" : ""
                          }`
                        : "small-contained-button mui-card-button mui-card-button-program d-flex"
                    }
                    onClick={
                      item.card_button.handleClick
                        ? () => {
                            item.card_button.handleClick(item);
                          }
                        : undefined
                    }>
                    {item.card_button.button_title}
                  </button>
                )}
                <ImageStyle
                  alt={item.title}
                  src={item.card_image}
                  onClick={
                    handleClick
                      ? () => {
                          handleClick(item);
                        }
                      : undefined
                  }
                />
                {MENU_OPTIONS && (
                  <div className="mui-custom-card-dots">
                    <CustomPopover menu={MENU_OPTIONS} data={item} />
                  </div>
                )}
              </Box>

              <Stack
                spacing={2}
                className="mui-custom-card-content"
                onClick={
                  handleClick
                    ? () => {
                        handleClick(item);
                      }
                    : undefined
                }>
                <h3 className="lesson-heading">
                  {htmlDecode(item.card_title)}
                </h3>
                {item.card_short_description && (
                  <p className="mui-custom-card-description normal-font">
                    {htmlDecode(item.card_short_description)}
                  </p>
                )}
                {item.card_short_description_2 && (
                  <p className="card_lower_title_description">
                    {htmlDecode(item.card_short_description_2)}
                  </p>
                )}
                {item.card_gray_description && (
                  <p className="mui_gray_description">
                    <span>{item.card_gray_description}</span>
                  </p>
                )}
              </Stack>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
