import React, { useState, useEffect, useCallback } from "react";
import CustomConfirmation from "src/components/CustomConfirmation";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import { ReactNormailVideoPlayer } from "src/components";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { deleteMeditationDailyApi } from "src/DAL/MeditationDaily/MeditationDaily";
import { useSnackbar } from "notistack";
import { CircularProgress, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";
import Upload from "./UploadImage";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  notchedOutline: {
    borderColor: "#fff !important",
  },
}));
export default function ImagesWall() {
  const { contentSettingData } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [note, setNote] = useState("");
  const [files, setFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [imagePreview, setImagePreview] = useState();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [uploadedImage, setUploadedImage] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    // Assuming only one file is uploaded at a time
    const file = acceptedFiles[0];

    const reader = new FileReader();

    // reader.onabort = () => console.log("file reading was aborted");
    // reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      // console.log(binaryStr);

      // Set the uploaded image to state
      setUploadedImage({
        file,
        preview: URL.createObjectURL(file),
      });
    };
    reader.readAsDataURL(file);
  }, []);

  const removeImage = () => {
    // Remove the uploaded image from state
    setUploadedImage(null);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const result = await deleteMeditationDailyApi(deleteValue._id);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });

      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const getDailyGratitude = async () => {
    setIsLoading(true);

    // const result = await listMeditationDailyApi(postData);
    // if (result.code === 200) {
    //   const meditations = result?.meditations?.map((meditation, index) => {
    //     return {
    //       ...meditation,
    //       meditation_name: meditation.meditation_name,
    //       meditationDate: moment(meditation.meditation_date).format(
    //         "YYYY-MM-DD"
    //       ),
    //       meditation_time: moment(meditation.meditation_date).format("hh:mm A"),
    //     };
    //   });

    //   setIsLoading(false);

    // } else {
    //   enqueueSnackbar(result.message, { variant: "error" });
    setIsLoading(false);

    // }
  };
  const handleRemove = (index) => {
    files.splice(index, 1);
    setFiles([...files]);
  };
  const handleChangeImage = (data) => {
    let old_array = [];

    setFiles(old_array);
    setOldImagesArray(old_array);
  };
  const handleUpload = (event) => {
    setImagePreview(URL.createObjectURL(event.target.files[0]));
    enqueueSnackbar("Image is Selected", { variant: "success" });
    let setImageObject = {};
    const fileList = event.target.files;
    const arr = [];

    for (let i = 0; i < fileList.length; i++) {
      setImageObject = {
        path: fileList[i],
        type: "file",
      };

      arr.push(setImageObject);
    }
    setFiles((prevFiles) => [setImageObject]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const results = files.map((image, index) => {
    //   const formData = new FormData();
    //   formData.append("image", image.path);
    //   formData.append("width", 500);
    //   console.log(...formData, "formData");
    //     const result = UploadImages(formData);
    //     return result;
    // });
    console.log(uploadedImage, note, "files");
    // Promise.all(results).then(async (img_results) => {
    //   var setImageArray = [];
    //   img_results.map((image_path, index) => {
    //     setImageArray.push(image_path);
    //   });
    //   const formDataObject = {
    //     challange: params.id,
    //     challange_date: moment(challengeDate).format("YYYY-MM-DD"),
    //   };
    //   if (files.length > 0) {
    //     formDataObject.images = setImageArray;
    //   }
    //   const result = await update_lifeScript_challange_history_by_member_api(
    //     formDataObject
    //   );
    //   if (result.code === 200) {
    //     enqueueSnackbar(result.message, { variant: "success" });
    //     let is_completed = false;
    //     if (
    //       result.data.is_reward_assigned &&
    //       result.challenge_reward_content.enable_reward_setting
    //     ) {
    //       setRewardData({ ...result.data, ...result.challenge_reward_content });
    //       setIsCompleted(true);
    //       is_completed = true;
    //     }
    //     getChallengeDetail(is_completed);
    //   } else {
    //     enqueueSnackbar(result.message, { variant: "error" });
    //     setIsLoadingForm(false);
    //   }
    // }
    // );
  };
  useEffect(() => {
    getDailyGratitude();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete Image?"}
        handleAgree={handleDeleteDiary}
      />

      <section className="container dynamite-diary">
        <div className="row">
          <div className="col-lg-6">
            <PageDescription
              parameter="wow_wall_heading"
              else_title="WoW Wall"
            />
            <h3 style={{ color: "#fff" }}>Share Your Wins</h3>
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
        <section className="container top-section-video mt-3 mb-lg-3">
          <div className="row pt-70" id="assessment-container">
            <div className="col-lg-7 col-md-6 mb-2">
              <div>
                <ReactNormailVideoPlayer
                  // url={contentSettingData?.wow_wall_url}
                  url={contentSettingData?.daily_meditation_url}
                />
              </div>

              <div className="lg-mt-4 mb-2">
                <div className="dropzone" {...getRootProps()}>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="drag-drop">Drop the Image here ...</p>
                  ) : (
                    <p className="drag-drop">
                      Drag 'n' Drop Image here, or Click to Upload Image.
                    </p>
                  )}
                  <button
                    type="button"
                    className="small-contained-button-search">
                    Upload Image &nbsp;&nbsp;{" "}
                    <Icon width={20} height={20} icon={"ic:sharp-upload"} />
                  </button>
                  {uploadedImage && (
                    <div className="uploaded-image-box">
                      <img
                        width={70}
                        height={70}
                        src={uploadedImage.preview}
                        alt="Uploaded"
                        className="uploaded-image"
                        style={{ objectFit: "cover" }}
                      />
                      <button
                        type="button"
                        className="remove-image-button"
                        onClick={removeImage}>
                        <Icon icon="fluent:delete-24-filled" />
                      </button>
                    </div>
                  )}
                </div>
                <div className="mt-4"></div>
                {/* <Upload
                  handleRemove={handleRemove}
                  files={files}
                  otherFiles={otherFiles}
                  imagePreview={imagePreview}
                  setFiles={setFiles}
                  handleUpload={handleUpload}
                  handleChangeImage={handleChangeImage}
                /> */}
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row lg-mt-4 mb-2">
                  <div className="col-12">
                    <TextField
                      fullWidth
                      className="custom-search-field"
                      label="Share Your Wins ..."
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      name="note"
                      required
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                    />
                  </div>
                  <div className="text-end mt-3">
                    <button className="small-contained-button">Submit</button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-5 col-md-6 text-md-start text-center">
              <div className="d-flex  w-100 images-wall  lg-mt-4 mb-2">
                <div className="container mt-2">
                  <div className="row">
                    {uploadedImage && (
                      <div className="col-md-6">
                        <img
                          src={uploadedImage.preview}
                          alt="Uploaded"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                        />
                        <p className="wow-wall-text">{note}</p>
                      </div>
                    )}
                    {uploadedImage && <div className="col-md-6"></div>}
                  </div>
                  {uploadedImage && (
                    <div className="row">
                      {uploadedImage && <div className="col-md-6"></div>}
                      {uploadedImage && (
                        <div className="col-md-6">
                          <img
                            src={uploadedImage.preview}
                            alt="Uploaded"
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "15px",
                            }}
                          />
                          <p className="wow-wall-text">{note}</p>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="row">
                    {uploadedImage && (
                      <div className="col-md-6">
                        <img
                          src={uploadedImage.preview}
                          alt="Uploaded"
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "15px",
                          }}
                        />
                        <p className="wow-wall-text">{note}</p>
                      </div>
                    )}
                    {uploadedImage && <div className="col-md-6"></div>}
                  </div>
                  {uploadedImage && (
                    <div className="row">
                      {uploadedImage && <div className="col-md-6"></div>}
                      {uploadedImage && (
                        <div className="col-md-6">
                          <img
                            src={uploadedImage.preview}
                            alt="Uploaded"
                            style={{
                              width: "100%",
                              height: "auto",
                              borderRadius: "15px",
                            }}
                          />
                          <p className="wow-wall-text">{note}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
