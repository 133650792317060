import React from "react";
import { useState } from "react";
import ReactPlayer from "react-player";

const ReactMeditationPlayer = (props) => {
  const [played, setPlayed] = useState(false);

  const handlePlay = () => {
    // if (!played) {
    //   props.onPlay(); // Trigger the callback function only once
    //   setPlayed(true);
    // }
    props.onPlay();
  };
  return (
    <ReactPlayer
      controls
      url={props.url}
      width="100%"
      pip
      stopOnUnmount
      playsinline
      className="set-max-height"
      onPlay={handlePlay}
      playing={props.playing}
      onPause={props.onPause}
    />
  );
};

export default ReactMeditationPlayer;
