import { invokeApi } from "../../bl_libs/invokeApi";

export const ListChild = async (id) => {
  const requestObj = {
    path: `api/child/get_child/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const AddChildApi = async (data) => {
  const requestObj = {
    path: `api/child`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const EditChildApi = async (data, id) => {
  const requestObj = {
    path: `api/child/${id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const DeleteChild = async (id) => {
  const requestObj = {
    path: `api/child/${id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const DetailChild = async (id) => {
  const requestObj = {
    path: `api/child/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const childProgrammeListing = async (id, child_id) => {
  const requestObj = {
    path: `api/program/list_parent_progam/${id}/${child_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
export const programmeAccessApiV1 = async (id, data) => {
  console.log(data, "hi i m here");
  const requestObj = {
    path: `api/member/add_program_against_member/v1/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const eventAccessApiV1 = async (id, data) => {
  const requestObj = {
    path: `api/member/add_event_against_member/${id}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const childEventListing = async (id, child_id) => {
  const requestObj = {
    path: `api/event/get_parent_event/${id}/${child_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};
