import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import StarIcon from "@mui/icons-material/Star";
import Rating from "@mui/material/Rating";
import {
  ReactNormailVideoPlayer,
  ReactVideoDurationPlayer,
} from "src/components";
import { menOne, menTwo } from "../../../assets";
import { s3baseUrl } from "../../../config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ReactNormailVideoPlayerNew from "src/components/ReactPlayers/ReactNormailVideoPlayerNew";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RatingCard({ review, reviews }) {
  const [expanded, setExpanded] = React.useState(false);
  const [videoProgressData, setVideoProgressData] = React.useState([]);
  const { handleVideoList } = useContentSetting();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    console.log(reviews, "reviewsreviewsreviewsreviewsreviews");
    let data = reviews.map((records) => {
      if (records.review_type == "video") {
        return {
          recording_id: records?._id,
          video_duration: records?.video_duration,
          total_video_duration: records?.total_video_duration,
          is_complete: records?.is_complete,
        };
      } else {
        return {
          recording_id: records?._id,
          video_duration: "00:00",
          total_video_duration: "00:00",
          is_complete: false,
        };
      }
    });
    setVideoProgressData(data);
    handleVideoList(data);
    localStorage.setItem("video_list", JSON.stringify(data));
    localStorage.setItem("video_type", "program");
  }, []);
  return (
    <Card sx={{ borderRadius: 1 }} className="card-full-width review-card">
      <div className="row ml-0 mr-0">
        <div className="col-12 feedback-programme-header set-max-height">
          {review.review_type == "image" ? (
            <img
              src={s3baseUrl + review.review_images_url.thumbnail_2}
              width="70"
              height="70"
              style={{ borderRadius: 50 }}
              className="mt-3"
            />
          ) : (
            <>
              {/* <ReactVideoDurationPlayer
                url={review.review_file_url}
                type={"program_review"}
                value={review}
                setVideoProgressData={setVideoProgressData}
                videoProgressData={videoProgressData}
              /> */}
              <ReactNormailVideoPlayerNew
                url={review.review_file_url}
                type={"program_review"}
                value={review}
                setVideoProgressData={setVideoProgressData}
                videoProgressData={videoProgressData}
              />
            </>
          )}
        </div>
        <div className="col-12 feedback-programme-header">
          <h5 className="feedback-programme-text">
            {htmlDecode(review.client_name)}
          </h5>
        </div>
        <div className="col-12 position-relative">
          <p className="review-font ps-3 pe-3 mb-5">
            {htmlDecode(review.short_description)}
          </p>
        </div>
        <div className="col-12 rating-stars-box">
          <Rating
            name="read-only"
            value={5}
            readOnly
            className="rating-stars"
          />
        </div>
      </div>
    </Card>
  );
}
