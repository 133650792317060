import React, { useState } from "react";

export default function Feelings() {
  const emojis = [
    "😀",
    "😃",
    "😄",
    "😁",
    "😆",
    "😅",
    "🤣",
    "😂",
    "🙂",
    "🙃",
    "😉",
    "😊",
    "😇",
    "🥰",
    "😍",
    "🤩",
    "😘",
    "😗",
    "😊",
    "😚",
    "😙",
    "😋",
    "😛",
    "😜",
    "🤪",
    "😝",
    "🤑",
    "🤗",
    "🤭",
    "🤫",
    "🤔",
    "🤐",
    "🤨",
    "😐",
    "😑",
    "😶",
    "😏",
    "😒",
    "🙄",
    "😬",
    "😮‍💨",
    "🤥",
    "😌",
    "😔",
    "😪",
    "🤤",
    "😴",
    "😷",
    "🤒",
    "🤕",
    "🤢",
    "🤮",
    "🤧",
    "🥴",
    "😵",
    "🤯",
    "🤠",
    "🥳",
    "😎",
    "🤓",
    "🧐",
    "😕",
    "😟",
    "🙁",
    "☹️",
    "😮",
    "😯",
    "😲",
    "😳",
    "🥺",
    "😓",
    "😞",
    "😣",
    "😖",
    "😱",
    "😭",
    "😢",
    "😥",
    "😰",
    "😨",
    "😧",
    "😦",
    "😩",
    "😫",
    "🥱",
    "😤",
    "😡",
    "😠",
    "🤬",
    "😈",
    "👿",
    "💀",
    "❤️",
    "🧡",
    "💛",
    "🤎",
    "🖤",
    "💜",
    "💙",
    "💚",
    "🤞",
    "🖖",
    "🤟",
    "🤘",
    "🤙",
    "👈",
    "🖐️",
    "👍",
    "👎",
    "👊",
    "🤛",
    "🤜",
    "👆",
    "👇",
    "☝️",
    "👏",
    "🙌",
    "🤝",
    "👀",
    "👁️",
    "💁‍♀️",
    "💁‍♂️",
    "💁",
    "🙆‍♀️",
    "🙇‍♀️",
    "🤦",
    "🤦‍♂️",
    "🤦‍♀️",
    "🤷",
    "🙋",
  ];
  const [droppedEmojis, setDroppedEmojis] = useState([]);

  const handleDragStart = (event, emoji) => {
    event.dataTransfer.setData("text/plain", emoji);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const emoji = event.dataTransfer.getData("text/plain");
    setDroppedEmojis([...droppedEmojis, emoji]);
    const imagesWall = document.querySelector(".emogi-wall");
    const emojiElement = document.createElement("div");
    emojiElement.textContent = emoji;
    imagesWall.appendChild(emojiElement);
  };

  return (
    <>
      <div>Feelings</div>
      <div
        className="emoji-box"
        onDragOver={handleDragOver}
        onDrop={handleDrop}>
        <div className="emoji-container">
          {emojis.map((emoji, index) => (
            <div
              key={index}
              className="emoji"
              draggable
              onDragStart={(event) => handleDragStart(event, emoji)}>
              {emoji}
            </div>
          ))}
        </div>
      </div>
      <div
        className="emogi-wall"
        onDragOver={handleDragOver}
        onDrop={handleDrop}></div>
    </>
  );
}
