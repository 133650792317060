import React from "react";
import { Box, Card, Link, Typography, Stack, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { s3baseUrl } from "../../../config/config";
import { htmlDecode } from "src/utils/convertHtml";
import LockIcon from "@mui/icons-material/Lock";

function LessonsCard({ programLockStatus, programSlug, lesson }) {
  const navigate = useNavigate();
  const handleClick = () => {
    // if (programLockStatus === false) {
    //   navigate(`/lessons/${lesson.lesson_slug}`, {
    //     state: lesson,
    //   });
    // } else
    if (lesson.is_paid === false) {
      navigate(`/lessons/${lesson.lesson_slug}`, {
        state: lesson,
      });
    }
  };
  return (
    <>
      <Card className="lesson-card" onClick={handleClick}>
        <div className="row p-3">
          <div className="col-lg-2 col-md-3 col-sm-12">
            <Box sx={{ position: "relative", cursor: "pointer" }}>
              {/* {programLockStatus === true && lesson?.is_paid === true ? ( */}
              {lesson?.is_paid === true ? (
                <button
                  variant="contained"
                  className="small-contained-button program-lock-icon top-50 start-50 translate-middle"
                  style={{
                    zIndex: 9,
                    top: "50%",
                    left: "50%",
                    fontSize: "14px",
                    position: "absolute",
                    padding: "10px 20px",
                    transform: "translate(-50%, -50%)",
                  }}>
                  <LockIcon className="lock-icon-color" />
                </button>
              ) : (
                ""
              )}
              <img
                className="lesson-card-image"
                src={s3baseUrl + lesson.lesson_images.thumbnail_3}
                alt="name"
              />
            </Box>
          </div>
          <div className="col-lg-10 col-md-9 col-sm-12">
            <h3 className="lesson-heading">{htmlDecode(lesson.title)} </h3>
            <p className="normal-font">
              {htmlDecode(lesson.short_description)}
            </p>
            {/* <p className="programme-duration">5 Lessons &nbsp; | &nbsp; 5 hr 16 min 19 sec</p> */}
          </div>
        </div>
      </Card>
    </>
  );
}

export default LessonsCard;
