import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import DoneIcon from "@mui/icons-material/Done";
export default function ActionBarComponentProps({
  diaryDate,
  handleChangeDate,
  availableDates,
  firstDiaryDate,
}) {
  const isDateAvailable = (date) => {
    const givenDate = new Date(date.$d);
    const isDateMatching = availableDates?.some((item) => {
      const itemDate = new Date(item.date);
      // Check if the item date matches the given date
      return itemDate.toDateString() === givenDate.toDateString();
    });

    return isDateMatching;
  };
  const isVideoAvailable = (date) => {
    const givenDate = new Date(date.$d);
    const matchingDate = availableDates?.find((item) => {
      const itemDate = new Date(item.date);
      return itemDate.toDateString() === givenDate.toDateString();
    });

    if (matchingDate && matchingDate.videos) {
      return true;
    } else {
      return false;
    }
  };

  const renderDay = (date, value, props) => {
    const combinedStyles = {
      ...props.style,
      color:
        date.isAfter(new Date()) || date.isBefore(firstDiaryDate)
          ? "#8a8a8a !important"
          : "#000",
      display: "flex",
      alignItems: "center",
      // position: "relative",
      cursor:
        date.isBefore(firstDiaryDate) || date.isAfter(new Date()) // Add condition to check if date is outside the range
          ? "not-allowed"
          : isVideoAvailable(date)
          ? "not-allowed"
          : "pointer",
      pointerEvents:
        date.isBefore(firstDiaryDate) || date.isAfter(new Date()) // Add condition to check if date is outside the range
          ? "none"
          : isVideoAvailable(date)
          ? "none"
          : "auto",
    };
    const iconStyle = {
      // position: "absolute",
      border: "none !important",
      stroke: "green",
      strokeWidth: "2px",
      position: "absolute", // Make the icon absolute positioned
      bottom: "1px", // Adjust top position for desired placement
      right: "10px",
      // top: "50%",
      // left: "50%",
      // transform: "translate(-50%, -50%)",
    };
    return (
      <PickersDay {...props} style={combinedStyles}>
        <div style={{ position: "relative" }}>{date.format("D")}</div>
        {isDateAvailable(date) && (
          <DoneIcon
            fontSize="medium"
            sx={{
              color: "green !important",
              width: "13px",
              height: "13px",
              ...iconStyle,
            }}
          />
        )}
      </PickersDay>
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="static-date-picker">
        <StaticDatePicker
          value={diaryDate}
          onChange={(newValue) => handleChangeDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={new Date()}
          minDate={firstDiaryDate ? firstDiaryDate : new Date()}
          renderDay={renderDay}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
        />
      </div>
    </LocalizationProvider>
  );
}
