import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { RecordNotFound } from "../_dashboard/programmes";
import { Icon } from "@iconify/react";
function PodsCards({ data, handleNavigate, notFoundtitle }) {
  const { contentSettingData } = useContentSetting();
  return (
    <div className="container">
      {contentSettingData && contentSettingData.pods_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: contentSettingData.pods_description,
          }}></div>
      ) : (
        <h2>Meetings</h2>
      )}
      {data.length > 0 ? (
        <div className="row">
          {data.map((value, index) => {
            return (
              <>
                <div className="col-lg-4 col-md-6 col-sm-12 mb-4 ">
                  <div
                    className="card mt-4 pods-cards-shadow cursor h-100"
                    onClick={() => handleNavigate(value)}>
                    <div className="card-img-container position-relative">
                      <img
                        src={s3baseUrl + value.room_image.thumbnail_1}
                        className="card-img-top pods-image"
                        alt="Meetings"
                      />
                      {value?.is_locked && (
                        <button className="small-contained-button position-absolute top-50 start-50 translate-middle">
                          <Icon icon="il:lock" className="pods-lock-icon" />{" "}
                          Locked
                        </button>
                      )}
                    </div>
                    <div className="card-body">
                      <h3 className="h2-heading">{htmlDecode(value.title)}</h3>
                      <p className="programme-card-desc mb-3">
                        {htmlDecode(value.short_description)}
                      </p>
                      <div className="card-button">
                        <p className="pods-active-members">
                          <span>Active Members {value.room_user.length}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <RecordNotFound title={notFoundtitle} />
      )}
    </div>
  );
}

export default PodsCards;
