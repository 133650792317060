import React, { useCallback, useEffect, useState } from "react";
import { useRef } from "react";
import ReactPlayer from "react-player";

import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useSnackbar } from "notistack";
import { videoDurationapi } from "src/DAL/Programmes/Programmes";

const ReactNormailVideoPlayerNew = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [videoDuration, setVideoDuration] = useState();
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDurationDM, setVideoDurationDM] = useState();
  const [videoProgressDM, setVideoProgressDM] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const playerRef = useRef();
  const videoDurationRef = useRef();
  const videoProgressRef = useRef();
  const { handleVideoList } = useContentSetting();
  const setVideoPlayed = () => {
    setIsPlay(true);
    if (props.setting && props.setting.video_type !== "repeat") {
      localStorage.setItem("is_seen_video", "Yes");
    }
  };
  const handleVideoDuration = (duration) => {
    videoDurationRef.current = duration;
    setVideoDuration(duration);
  };

  const handleVideoProgress = (progress) => {
    videoProgressRef.current = progress;
    setVideoProgress(progress);
  };
  const handleVideoPause = async () => {
    setIsPlay(false);
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    if (videoDuration - videoProgress.playedSeconds > 2) {
      let postData = {
        recording_id: props.value._id,
        // video_duration: videoProgress.playedSeconds,
        video_duration: playedSecondsString,
        is_complete: false,
        total_video_duration: videoDurationString,
        // type: props.type,
        // total_video_duration: videoDuration,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      let postData = {
        recording_id: props.value._id,
        video_duration: playedSecondsString,
        is_complete: true,
        total_video_duration: videoDurationString,
        // type: props.type,
      };
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };
  const handlePageUnmount = async (
    videoDurationString,
    playedSecondsString
  ) => {
    setIsPlay(false);
    if (videoDurationString - playedSecondsString > 2) {
      let postData = {
        recording_id: props.value._id,
        // video_duration: videoProgress.playedSeconds,
        video_duration: playedSecondsString
          ? playedSecondsString
          : props.value?.video_duration,
        is_complete: false,
        total_video_duration: videoDurationString,
        // type: props.type,
        // total_video_duration: videoDuration,
      };
      console.log(postData, "dfkdslfjlasd");
      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } else {
      let postData = {
        recording_id: props.value._id,

        video_duration: playedSecondsString
          ? playedSecondsString
          : props.value?.video_duration,
        is_complete: true,
        total_video_duration: videoDurationString,
        // type: props.type,
      };
      console.log(postData, "dfkdslfjlasd");

      const result = await videoDurationapi(postData, props.type);
      if (result.code === 200) {
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    }
  };

  const handleVideoEnded = async () => {
    console.log("end function");
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    let postData = {
      recording_id: props.value._id,
      video_duration: playedSecondsString,
      is_complete: true,
      total_video_duration: videoDurationString,
      // type: props.type,
    };
    const result = await videoDurationapi(postData, props.type);
    if (result.code === 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const onReady = useCallback(() => {
    if (!isReady) {
      let videoList = JSON.parse(localStorage.getItem(props.value._id)) || {};

      // console.log(
      //   videoList,
      //   "fldksjfkdkhgksdf",
      //   Object.keys(videoList)[0],
      //   videoList[props.url]
      // );
      let timeToStart = 0;
      if (videoList && Object.keys(videoList)[0] == props.url) {
        timeToStart = parseFloat(videoList[props.url] - 0.5);
      } else timeToStart = parseFloat(props.value?.video_duration - 0.5);
      if (timeToStart <= 0) {
        playerRef.current.seekTo(0);
      } else {
        playerRef.current.seekTo(timeToStart, "seconds");
      }

      setIsReady(true);
    }
  }, [isReady]);

  const updateVideoData = () => {
    const videoList = JSON.parse(localStorage.getItem("video_list")) || [];
    const videoIndex = videoList?.findIndex(
      (video) => video.recording_id === props.value._id
    );
    // If the video is found
    const videoDurationString = videoDuration.toString();
    const playedSecondsString = videoProgress.playedSeconds.toString();
    if (videoIndex !== -1) {
      if (videoDuration - videoProgress.playedSeconds > 2) {
        videoList[videoIndex].video_duration = playedSecondsString;
        videoList[videoIndex].total_video_duration = videoDurationString;
        videoList[videoIndex].url = props.url;
        videoList[videoIndex].is_complete = false;
      } else {
        videoList[videoIndex].video_duration = playedSecondsString;
        videoList[videoIndex].total_video_duration = videoDurationString;
        videoList[videoIndex].is_complete = true;
        videoList[videoIndex].url = props.url;
      }
    } else {
      console.log("in there");
    }

    console.log(videoList);
    localStorage.setItem("video_list", JSON.stringify(videoList));
    localStorage.setItem("video_type", props?.type);
    handleVideoList(videoList);

    // ======new list to gat its duration as locally  =====

    let videoLists = JSON.parse(localStorage.getItem(props?.value._id)) || {};
    videoLists[props.url] = playedSecondsString;
    localStorage.setItem(props?.value?._id, JSON.stringify(videoLists));
  };

  useEffect(() => {
    if (isPlay) {
      updateVideoData();
    }
  }, [videoProgress]);

  useEffect(() => {
    // if (props.type == "program_section") {
    //   localStorage.setItem(
    //     "video_list",
    //     JSON.stringify(props.videoProgressData)
    //   );
    //   console.log(
    //     localStorage.getItem("video_list"),
    //     "jffjsdjkhfja",
    //     props.videoProgressData
    //   );
    // }
    videoDurationRef.current = videoDuration;

    return () => {
      // Access the video duration and progress from the refs when the component is unmounted

      let videoList = JSON.parse(localStorage.getItem("video_list")) || [];
      let videoIndex = videoList?.findIndex(
        (video) => video.recording_id === props?.value?._id
      );

      let videoDurationString = videoDurationRef.current.toString()
        ? videoDurationRef.current.toString()
        : videoList[videoIndex].total_video_duration;
      let playedSecondsString =
        videoProgressRef.current.playedSeconds.toString()
          ? videoProgressRef.current.playedSeconds.toString()
          : videoList[videoIndex].video_duration;
      console.log(videoDurationString, "dsjhfkdsf", playedSecondsString);
      // Call the handleVideoPause function with the exact video duration and progress when the component is unmounted
      handlePageUnmount(videoDurationString, playedSecondsString);
    };
  }, []);
  return (
    <ReactPlayer
      controls
      url={props.url}
      width="100%"
      pip
      stopOnUnmount
      playsinline
      // volume={0}
      className="set-max-height"
      // muted={0}
      // uncomment these
      // onPlay={setVideoPlayed}
      // onDuration={handleVideoDuration}
      // onProgress={handleVideoProgress}
      // onPause={handleVideoPause}
      // onEnded={handleVideoEnded}
      // onReady={onReady}
      // ref={playerRef}
    />
  );
};

export default ReactNormailVideoPlayerNew;
