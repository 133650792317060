import { CircularProgress, IconButton, Slider } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactNormailVideoPlayer } from "src/components";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import moment from "moment";
import { get_root_value } from "src/utils/domUtils";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import LockIcon from "@mui/icons-material/Lock";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {
  challenge_member_list_api,
  list_challenge_member,
  meditation_challenge_list_api,
} from "src/DAL/Challenges/Challenges";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { s3baseUrl } from "src/config/config";
import { playIcon } from "src/assets";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function PastChallenges() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [challengeList, setChallengeList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [challengeId, setChallengeId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [memberArray, setMemberArray] = useState([]);
  const [data, setData] = useState([
    { id: "788798" },
    { id: "788798" },
    { id: "788798" },
    { id: "788798" },
  ]);

  const getChallengeListing = async () => {
    const result = await list_challenge_member();
    if (result.code === 200) {
      setChallengeList(result.completed_challenge_history);

      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getChallengeListing();
  }, []);
  const handleAcceptChallenge = async (value) => {
    navigate(`/challenge-detail/${value._id}`, {
      state: value,
    });
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(challengeList, "challengeList");

  return (
    <div className="container">
      <div className="row pt-70" id="assessment-container">
        <div className="col-6 mb-4">
          <h2>Past Activities</h2>
        </div>
        <div className="col-lg-6 text-end"></div>
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/challenges")}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      {challengeList.length > 0 ? (
        <>
          {challengeList?.map((data, index) => {
            return (
              <div
                className="row challenge-box position-relative mb-4"
                onClick={() => handleAcceptChallenge(data.challenge_id)}
                style={{ cursor: "pointer" }}>
                <div className="col-lg-4 col-md-12 image-div position-relative">
                  <img
                    src={
                      s3baseUrl +
                      data?.challenge_id?.video_thumbnail?.thumbnail_1
                    }
                    alt="Challenge Image"
                    className="image"
                    width={320}
                    height={230}
                    style={{ objectFit: "fill" }}
                  />
                  <div className="play-icon">
                    {/* <i class="fa-solid fa-play fa-2x"></i> */}
                    <img src={playIcon} />
                  </div>
                </div>
                <div className="col-lg-8 col-md-12 text-md-start text-center mt-md-0 mt-4 visioneering-box position-relative">
                  <div className="assetssment-para visioneering-list-description">
                    <h3>{data?.challenge_id?.title}</h3>
                    <p>{data?.challenge_id?.short_description}</p>
                  </div>
                  <div className="text-end view-challenge-button-box mt-3 mt-md-0">
                    <button
                      className="small-contained-button d-flex"
                      onClick={() => handleAcceptChallenge(data.challenge_id)}>
                      View Challenge{" "}
                      <NavigateNextIcon
                        sx={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "3px",
                        }}
                      />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <RecordNotFound title={"No Past Activities"} />
        </>
      )}
    </div>
  );
}
