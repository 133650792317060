import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import DoneIcon from "@mui/icons-material/Done";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";

export default function ActionBarComponentProps({
  diaryDate,
  handleChangeDate,
  firstDiaryDate,
  endDiaryDate,
  notesSection,
}) {
  // Convert ISO strings to Dayjs objects
  const firstDiaryDayjs = dayjs(firstDiaryDate);
  const endDiaryDayjs = dayjs(endDiaryDate);

  // Extract dates from history
  const markedDates = notesSection.history.map((entry) =>
    dayjs(entry.date).startOf("day").format("YYYY-MM-DD")
  );

  // Check if a date is available
  const isDateAvailable = (date) => {
    const formattedDate = date.format("YYYY-MM-DD");
    return markedDates.includes(formattedDate);
  };

  // Custom render function for the day
  const renderDay = (date, value, props) => {
    const combinedStyles = {
      ...props.style,
      color:
        date.isAfter(endDiaryDayjs) || date.isBefore(firstDiaryDayjs)
          ? "#8a8a8a !important"
          : "#000",
      display: "flex",
      alignItems: "center",
      // cursor:
      //   date.isBefore(firstDiaryDayjs) || date.isAfter(endDiaryDayjs)
      //     ? "not-allowed"
      //     : "pointer",
      // pointerEvents:
      //   date.isBefore(firstDiaryDayjs) || date.isAfter(endDiaryDayjs)
      //     ? "none"
      //     : "auto",
    };
    const iconStyle = {
      border: "none !important",
      stroke: "green",
      strokeWidth: "2px",
      position: "absolute",
      bottom: "1px",
      right: "10px",
    };
    return (
      <PickersDay {...props} style={combinedStyles}>
        <div style={{ position: "relative" }}>{date.date()}</div>
        {isDateAvailable(date) && (
          <DoneIcon
            fontSize="medium"
            sx={{
              color: "green !important",
              width: "13px",
              height: "13px",
              ...iconStyle,
            }}
          />
        )}
      </PickersDay>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className="static-date-picker">
        <StaticDatePicker
          value={diaryDate}
          onChange={(newValue) => handleChangeDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          maxDate={endDiaryDate ? endDiaryDayjs : new Date()}
          minDate={firstDiaryDate ? firstDiaryDayjs : new Date()}
          componentsProps={{
            actionBar: {
              actions: ["today"],
            },
          }}
          renderDay={renderDay}
        />
      </div>
    </LocalizationProvider>
  );
}
