import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import { IconButton, CircularProgress, Slider, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChallengeDetailTabs from "./ChallengeDetailTabs";
import Notes from "src/components/Challenges/Notes";
import { challenges_resources_api } from "src/DAL/Challenges/Challenges";
import { ReactNormailVideoPlayer } from "src/components";
import moment from "moment";
import ActionBarComponentProps from "./calender";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function ChallengeDetail() {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { state } = useLocation();
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [confettiPlay, setConfettiPlay] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [resourcesList, setResourcesList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [notesSection, setNotesSection] = useState([]);
  const [diaryDate, setDiaryDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [firstDate, setFirstDate] = useState("");
  const [completeDate, setCompleteDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const isDateCompleted = (date) => {
    if (notesSection && notesSection.is_completed == true) {
      return true;
    } else {
      return notesSection?.history?.some(
        (entry) => moment(entry.date).format("YYYY-MM-DD") === date
      );
    }
  };
  const handleOpenSimpleBox = () => {
    // if (notesSection && notesSection.is_completed == true) {
    // } else {
    //   setOpenCreateModal(true);
    // }
    if (!isDateCompleted(diaryDate)) {
      setOpenCreateModal(true);
    }
  };
  const handleChangeDate = (value) => {
    const startDate = new Date(value.$d);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDiaryDate(formattedDate);
  };

  const handleRecordingsandResources = async () => {
    setIsLoading(true);
    let result = await challenges_resources_api(params?.id);
    if (result.code === 200) {
      setProgrammesDetail(result.challenge);
      const addeddate = moment(result?.challenge_note?.createdAt)
        .add(result.challenge?.number_of_days, "days")
        .toISOString();
      setCompleteDate(addeddate);
      setResourcesList(result.challenge_resources);
      setRecordingList(result.challenge_recordings);
      setNotesSection(result?.challenge_note);
      setFirstDate(result?.challenge_note?.createdAt);
      const createdAt = moment(result?.challenge_note?.createdAt);
      const numberOfDays = result.challenge?.number_of_days || 0;
      const totalDays = numberOfDays == 0 ? 0 : numberOfDays - 1;
      const newDate = createdAt.add(totalDays, "days");
      const today = moment();
      if (newDate.isBefore(today)) {
        setEndDate(newDate?._d);
      } else {
        setEndDate(new Date());
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    handleRecordingsandResources();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <section className="container dynamite-diary">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <IconButton
              className="back-screen-button"
              onClick={() => navigate(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Tooltip
              title={
                isDateCompleted(diaryDate)
                  ? "Already completed on this date!"
                  : ""
              }>
              <button
                className="small-contained-button float-end mt-1"
                // style={{
                //   cursor:
                //     notesSection && notesSection.is_completed == true
                //       ? "no-drop"
                //       : "pointer",
                // }}
                style={{
                  cursor: isDateCompleted(diaryDate) ? "no-drop" : "pointer",
                }}
                onClick={() => handleOpenSimpleBox()}>
                {/* {notesSection && notesSection.is_completed == true
                ? "Completed"
                : "Mark as Complete"} */}
                {isDateCompleted(diaryDate) ? "Completed" : "Mark as Complete"}
                <p className="mb-0" style={{ color: "#9f00ff" }}>
                  {diaryDate}
                </p>
              </button>
            </Tooltip>
          </div>
          {/* <div className="col-12">
            <p
              className="text-end mt-1 meditaion-date"
              style={{ marginRight: "52px" }}>
              {diaryDate}
            </p>
          </div> */}
        </div>

        <div className="row section-space">
          <div className="col-12">
            <h1>{programmesDetail?.title}</h1>
          </div>
        </div>

        <section className="container top-section-video mt-3 mb-lg-3">
          <div className="row pt-70" id="assessment-container">
            <div className="col-lg-7 col-md-6 mb-2">
              <ReactNormailVideoPlayer url={programmesDetail?.video_url} />
            </div>
            <div className="col-lg-5 col-md-6 text-md-start text-center">
              <div
                className="d-flex calender w-100 full-open-calender lg-mt-4 mb-2"
                style={{
                  height: "97%",
                }}>
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  firstDiaryDate={firstDate}
                  endDiaryDate={endDate}
                  notesSection={notesSection}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="col-12 d-flex m-2">
          <div className="col-6 days-in-total-text">
            <p>
              <span>Start Date</span> :{" "}
              <span>
                {" "}
                {moment(notesSection?.createdAt).format("DD-MM-YYYY")}
              </span>
            </p>
          </div>

          <div className="col-6 text-end float-end days-in-total-text">
            {/* <p>
              <span>Current Day / Total Days :</span>
              <span style={{ marginRight: "23px" }}>
                {" "}
                {notesSection.days_between +
                  "/" +
                  programmesDetail?.number_of_days}
              </span>
            </p> */}
          </div>
        </div>
        <div className="col-12 ps-lg-2 ps-md-1 mt-4">
          <div className="d-flex align-items-end">
            <div style={{ width: "98%" }}>
              {/* <div className="text-end days-in-total-text">
              <p>
                  <span>Current Day / Total Days :</span>
                  <span>
                    {" "}
                    {notesSection.days_between +
                      "/" +
                      programmesDetail?.number_of_days}
                  </span>
                </p>
              </div> */}
              <div class="habits-slider d-flex justify-content-between mb-2">
                <div class="slider-value-detail habits-gray-description mb-2">
                  {/* <span>{`${Math.round(
                        (data.completed_days /
                          data?.challenge_id?.number_of_days) *
                          100
                      )}%`}</span> */}
                  <span>Status</span>
                </div>
                <Slider
                  style={{
                    color: "#fff",
                  }}
                  aria-label="Custom marks"
                  // valueLabelFormat={`${
                  //   notesSection.completed_days > 1 ? "Day" : "Day"
                  // } ${notesSection.completed_days}`}
                  // valueLabelFormat={`${Math.round(
                  //   (notesSection?.completed_days /
                  //     programmesDetail?.number_of_days) *
                  //     100
                  // )}% Completed`}
                  defaultValue={
                    (notesSection.completed_days /
                      programmesDetail?.number_of_days) *
                    100
                  }
                  marks={[
                    {
                      value: 0,
                      label: "Day 1",
                    },

                    {
                      value: 100,
                      label: `Day ${programmesDetail?.number_of_days}`,
                    },
                  ]}
                  // valueLabelDisplay="on"
                  step={10}
                  className="mui-slider mt-4"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row section-space">
          <div className="col-12">
            <ChallengeDetailTabs
              resources={resourcesList}
              recordingList={recordingList}
              programmesDetail={programmesDetail}
              notesSection={notesSection.history}
            />
          </div>
        </div>
      </section>
      <Notes
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
        setConfettiPlay={setConfettiPlay}
        confettiPlay={confettiPlay}
        challengeId={state._id}
        programmesDetail={programmesDetail}
        handleRecordingsandResources={handleRecordingsandResources}
        diaryDate={diaryDate}
        completeDate={completeDate}
        notesSection={notesSection}
      />
    </>
  );
}
