import { Icon } from "@iconify/react";
import { useEffect, useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import unlockFill from "@iconify/icons-eva/unlock-fill";
import personFill from "@iconify/icons-eva/person-fill";
import creditCardFilled from "@iconify/icons-eva/credit-card-fill";
import dollarCircleFilled from "@iconify/icons-ant-design/dollar-circle-filled";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

import ChangePassword from "./ChangePassword";
import { allVideoDurationapi } from "src/DAL/Programmes/Programmes";
// material
import { alpha } from "@mui/material/styles";
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
// components
import MenuPopover from "../../components/MenuPopover";
//
import account from "../../_mocks_/account";
import { logout, parentChildLogin } from "../../DAL/Login/Login";
import { _get_user_from_localStorage } from "src/DAL/localStorage/localStorage";
import { s3baseUrl } from "src/config/config";
import { get_root_value } from "src/utils/domUtils";
import { ContentSettingApi } from "src/DAL/ContentSetting/ContentSetting";
import moment from "moment";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { user } from "src/assets";
import { uploadVisionBoard } from "src/DAL/VisionBoard/VisionBoard";

// import ChangeCard from "src/components/ChangeCard/ChangeCard";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Edit Profile",
    icon: personFill,
    linkTo: "/edit-profile",
  },
  // {
  //   label: "Settings",
  //   icon: settings2Fill,
  //   linkTo: "#",
  // },
  {
    label: "Settings",
    icon: settings2Fill,
    linkTo: "/payment-request",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [openChangeCard, setOpenChangeCard] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const {
    userInfo,
    parentChildInfo,
    handleUserInfo,
    handleNotificationsCount,
    handleGeneralSettingData,
  } = useContentSetting();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleProfile = (value) => {
    navigate(value);
    handleClose();
  };
  const handleSaveBoard = async (e) => {
    let postData = JSON.parse(localStorage.getItem("auto_save_board"));
    console.log(postData, "postData");
    if (!postData) {
      return;
    }
    const result = await uploadVisionBoard(postData);
    if (result.code == 200) {
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handleSwitch = async (data) => {
    handleSaveBoard();
    let postData = {
      email: data?.email,
    };
    localStorage.removeItem("auto_save_board");
    const result = await parentChildLogin(postData);
    if (result.code === 200) {
      console.log(result, "login with email");
      localStorage.setItem("token", result.token);
      localStorage.setItem(`user_data`, JSON.stringify(result.member));
      localStorage.setItem(
        `user_gratitude_coins`,
        JSON.stringify(result?.member?.gratitude_coins)
      );
      handleUserInfo(result?.member);
      handleNotificationsCount(result?.unread_notification_count);
      handleGeneralSettingData(
        result?.member?.gratitude_coins +
          result?.member?.meditation_coins +
          result?.member?.affirmation_coins +
          result?.member?.challenges_coins
      );
      handleClose();
      navigate("/dashboard", { replace: true });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const handlePopup = () => {
    // setOpen(false);
  };

  // const handleVideoPause = async () => {
  //   let allVideoList = JSON.parse(localStorage.getItem("video_list"));
  //   let videoType = localStorage.getItem("video_type");
  //   let data = { recording_array: allVideoList };

  //   const result = await allVideoDurationapi(data, videoType);
  //   if (result.code === 200) {
  //     localStorage.clear("video_list");
  //     localStorage.clear("video_type");
  //   } else {
  //     enqueueSnackbar(result.message, { variant: "error" });
  //   }
  // };
  const handleLogout = async () => {
    // const formdata = new FormData();
    // formdata.append('token', localStorage.getItem('token'));
    // const result = await logout(formdata);
    // if (result.code === 200) {
    // if (localStorage.getItem("video_list")) {
    //   handleVideoPause();
    // }
    // localStorage.removeItem("user_data");
    localStorage.removeItem("token");
    localStorage.removeItem("parent_childs");
    navigate("/login");
    // } else {
    //   enqueueSnackbar(result.message, { variant: 'error' });
    // }
  };
  const handleChange = () => {
    handleClose();
    navigate(`/dashboard`);
  };
  useEffect(() => {
    const filteredArray = parentChildInfo?.filter(
      (child) => child._id !== userInfo._id
    );
    setAccounts(filteredArray);
  }, [userInfo, parentChildInfo]);
  return (
    <>
      <ChangePassword
        openPasswordModal={openPasswordModal}
        setopenPasswordModal={setopenPasswordModal}
      />
      {/* <ChangeCard
        openChangeCard={openChangeCard}
        setOpenChangeCard={setOpenChangeCard}
      /> */}
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
            color: get_root_value("--portal-theme-primary"),
          }),
        }}>
        {userInfo !== null &&
        userInfo !== undefined &&
        userInfo !== "undefined" ? (
          <Avatar
            src={s3baseUrl + userInfo?.profile_image}
            alt={userInfo?.first_name + " " + userInfo?.last_name}>
            {/* {userInfo?.first_name + userInfo?.last_name} */}
          </Avatar>
        ) : (
          ""
        )}
      </IconButton>

      <MenuPopover
        Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}>
        {userInfo !== null &&
        userInfo !== undefined &&
        userInfo !== "undefined" ? (
          <Box sx={{ my: 1.5, px: 2.5 }} onClick={handleChange} color="inherit">
            <Typography variant="subtitle1" noWrap className="fix-weight">
              {userInfo?.first_name + " " + userInfo?.last_name}
            </Typography>
            <Typography variant="body2" noWrap className="fix-weight">
              {userInfo?.email}
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            // to={option.linkTo}
            // component={RouterLink}
            onClick={() => handleProfile(option.linkTo)}
            sx={{ typography: "body2", py: 1, px: 2.5 }}>
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
                color: get_root_value("--portal-theme-primary"),
              }}
            />

            <span className="fix-weight">{option.label}</span>
          </MenuItem>
        ))}
        {accounts && accounts.length > 0 && <Divider>Other Accounts</Divider>}

        {accounts &&
          accounts.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleSwitch(option)}
              sx={{
                typography: "body2",
                py: 1,
                px: 2.5,
                display: "flex",
                justifyContent: "space-between",
              }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  style={{
                    width: 25,
                    height: 25,
                    borderRadius: "50%",
                    marginRight: 15,
                  }}
                  src={
                    option.profile_image
                      ? s3baseUrl + option.profile_image
                      : user
                  }
                  alt="img"
                />
                <span className="title fix-weight">
                  {option.first_name} {option.last_name}
                </span>
              </div>
              <Box
                component={Icon}
                icon={"circum:lock"}
                sx={{
                  ml: 2,
                  width: 20,
                  height: 20,
                  color: get_root_value("--portal-theme-primary"),
                }}
              />
            </MenuItem>
          ))}
        {/* <MenuItem
          key=""
          // to={option.linkTo}
          // component={RouterLink}
          onClick={() => {
            navigate(`/transaction`);
            handleClose();
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={dollarCircleFilled}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Transactions
        </MenuItem> */}
        {/* <MenuItem
          key=""
          // to={option.linkTo}
          // component={RouterLink}
          onClick={() => {
            navigate(`/manage-card`);
            handleClose();
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={creditCardFilled}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Manage Card
        </MenuItem> */}

        {/* <MenuItem
          key=""
          // to={option.linkTo}
          // component={RouterLink}
          onClick={() => {
            handleClose();
            setopenPasswordModal(true);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={unlockFill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Change Password
        </MenuItem> */}
        {/* <MenuItem
          key=""
          // to={option.linkTo}
          // component={RouterLink}
          onClick={() => {
            handleClose();
            navigate(`/manage-subscription`);
          }}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
              color: get_root_value("--portal-theme-primary"),
            }}
          />
          Subscriptions
        </MenuItem> */}
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            sx={{ fontWeight: 600 }}
            onClick={() => handleLogout()}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// {
//   title: "Billing",
//   path: "/billing",
//   icon: getIcon(peopleFill),
// },
// {
//   title: "CreditCard",
//   path: "/creditcard",
//   icon: getIcon(peopleFill),
// },
// {
//   title: "All Transactions",
//   path: "/transaction",
//   icon: getIcon(peopleFill),
// },
