import React, { useContext, useEffect, useState } from "react";
import {
  get_consultant_data_localStorage,
  get_navbar_list_localStorage,
  get_project_info_localStorage,
  _get_admin_time_zone_localStorage,
  _get_client_dashboard_setting_localStorage,
  _get_content_setting_localStorage,
  _get_content_general_setting_localStorage,
  _get_home_event_localStorage,
  _get_navbar_list_localStorage,
  _get_testmonial_localStorage,
  _get_user_from_localStorage,
  _get_parent_child_from_localStorage,
  _get_videos_list_localStorage,
} from "src/DAL/localStorage/localStorage";
import { ProjectInfoApi } from "src/DAL/Profile/Profile";
import io from "socket.io-client";
import { socketBaseUri } from "src/config/config";
const CreateContentSetting = React.createContext();
const content_setting = _get_content_setting_localStorage();
const general_setting = _get_content_general_setting_localStorage();
const client_dashboard_setting = _get_client_dashboard_setting_localStorage();
const get_testmonial_localStorage = _get_testmonial_localStorage();
const get_home_event_localStorage = _get_home_event_localStorage();
const get_admin_time_zone_localStorage = _get_admin_time_zone_localStorage();
const get_project_info = get_project_info_localStorage();
const _get_user_info = _get_user_from_localStorage();
const _get_parent_child_info = _get_parent_child_from_localStorage();
const _get_consultant_data = get_consultant_data_localStorage();
const get_navbar_list = _get_navbar_list_localStorage();
const get_videos_list = _get_videos_list_localStorage();
let socket = {};
if (_get_user_info?._id) {
  socket = io(socketBaseUri + `?user_id=${_get_user_info?._id}`);
}
export const useContentSetting = () => useContext(CreateContentSetting);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    get_admin_time_zone_localStorage
  );
  const [contentSettingData, setContentSettingData] = useState(content_setting);
  const [generalSettingData, setGeneralSettingData] = useState(general_setting);
  const [projectInfo, setProjectInfo] = useState(get_project_info);
  const [userInfo, setUserInfo] = useState(_get_user_info);
  const [parentChildInfo, setParentChildInfo] = useState(
    _get_parent_child_info
  );
  const [consultantInfo, setConsultantInfo] = useState(_get_consultant_data);
  const [navbarList, setNavbarList] = useState(get_navbar_list);
  const [videoList, setVideoList] = useState(get_videos_list);
  const [stripeKey, setStripeKey] = useState("");
  const [dashboardEventData, setDashboardEventData] = useState(
    get_home_event_localStorage
  );
  const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
    get_testmonial_localStorage
  );
  const [dashboardSettingData, setDashboardSettingData] = useState(
    client_dashboard_setting
  );
  const [isConnected, setIsConnected] = useState(socket.connected);

  const [notificationsCount, setNotificationsCount] = useState(0);

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */
  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketBaseUri + `?user_id=${user_id}`);
  };
  const STOP_SOCKET_FORCEFULLY = (user_id) => {
    socket.disconnect();
  };
  const handleNotificationsCount = (val) => {
    setNotificationsCount(val);
  };
  const handleContentSettingData = (val) => {
    setContentSettingData(val);
  };
  const handleGeneralSettingData = (val) => {
    setGeneralSettingData(val);
  };
  const handleDashboardSettingData = (val) => {
    setDashboardSettingData(val);
  };
  const handleDashboardTestimonialData = (val) => {
    setDashboardTestimonialData(val);
  };
  const handleDashboardEventData = (val) => {
    setDashboardEventData(val);
  };
  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };
  const handleUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleParentChild = (val) => {
    setParentChildInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };

  const handleNavbarList = (val) => {
    setNavbarList(val);
  };
  const handleVideoList = (val) => {
    setVideoList(val);
  };
  const handleStripeKey = (val) => {
    setStripeKey(val);
  };
  //Getting Project Info from API
  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      // console.log(result, "sdfjlksd");
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result?.member_setting)
      );
      setProjectInfo(result?.member_setting);
    }
  };

  const collection = {
    adminTimeZone,
    navbarList,
    videoList,
    consultantInfo,
    userInfo,
    parentChildInfo,
    stripeKey,
    projectInfo,
    dashboardEventData,
    contentSettingData,
    generalSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    handleContentSettingData,
    handleGeneralSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    setUserInfo,
    setParentChildInfo,
    handleAdminTimeZone,
    handleUserInfo,
    handleParentChild,
    handleConsultantInfo,
    handleStripeKey,
    handleNavbarList,
    handleVideoList,
    setNotificationsCount,
    notificationsCount,
    handleNotificationsCount,
    socket,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
  };
  // useEffect(() => {
  // if (userInfo?._id && !isConnected) {
  //   socket.emit("live_event_room", userInfo._id);
  //   setIsConnected(true);
  // }
  // return () => {};
  // console.log(isConnected, "isConnected");
  // }, [userInfo,isConnected]);
  useEffect(() => {
    getProjectInfo();
  }, []);
  useEffect(() => {
    localStorage.setItem(`user_data`, JSON.stringify(userInfo));
  }, [userInfo]);
  useEffect(() => {
    localStorage.setItem(`parent_childs`, JSON.stringify(parentChildInfo));
  }, [parentChildInfo]);
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
